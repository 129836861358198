* {

	font-size: 14px;
	line-height: 20px;
}

body {
	font-family: $font;
	color: $primaryColor;
	font-weight: 400;
}

html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

* {
	box-sizing: border-box;
}

button {
	cursor: pointer;
}

button,
input,
select {
	font-family: $font;

	&:focus,
	&:active,
	&:focus + &:active {
		outline: none;
		box-shadow: none;
	}

}

select {
	font-family: $font;
	font-weight: bold;
}

.btn {

	&:focus,
	&:active,
	&:focus + &:active {
		outline: none;
		box-shadow: none;
	}
}

h1,
h2,
h3 {
	margin: 0;
	padding: 0;
}


a,
a:hover,
a:focus {
	outline: none;
	text-decoration: none;
	color: $primaryColor;
}


button {
	outline: none;
}


.btn {
	font-size: 13px;
	font-weight: 500;
	padding: 5px 15px;
	border-radius: 3px;

	&.btn-md {
		padding: 7px 30px;
		font-size: 13px;
	}

	&.btn-primary {
		border: 0;
		background-color: $primaryColor;
		color: #fff;
	}

	&.btn-warning {
		border: 0;
		background-color: $primaryLightColor;
		color: #fff;
	}

	&.btn-green {
		border: 0;
		background-color: #75C940;
		color: #fff;
	}

	&.btn-send {
		border: 0;
		background-color: $primaryColor;
		color: #fff;
		padding: 17px 50px;

		i {
			color: $fourthColor;
			margin-right: 10px;
			font-size: 20px;
			float: left;
		}
	}
}

.big-title-area {
	text-align: center;
	margin-bottom: 30px;


	> .title-area {
		position: relative;

		.big-title {
			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			width: 100%;
			font-size: 60px;
			line-height: 60px;
			font-weight: 300;
			color: $primaryColor;
			margin-bottom: 30px;
			@include opacity(0.1);
			z-index: 2;

			@include screen(mobile) {
				font-size: 30px;
			}
		}

		.title {
			position: relative;
			z-index: 3;
			font-size: 36px;
			line-height: 60px;
			font-weight: 500;
			color: $primaryColor;
			margin-bottom: 30px;

			@include screen(mobile) {
				font-size: 30px;
			}
		}
	}
}

.back-image {
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.see-link {
	display: inline-block;
	width: 100%;
	text-align: center;

	a {
		&.see-all {
			&.white {
				color: #fff;
				border-color: #fff;
			}

			font-size: 14px;
			line-height: 48px;
			border-width: 2px;
			border-style: solid;
			color: $primaryColor;
			border-radius: 3px;
			padding: 14px 34px;
			border-color: $thirdColor;
		}
	}
}




.productPagesPagination {
	margin-top: 70px;
	margin-bottom: 80px;

	ul {


		li {
			margin-right: 10px;



			&:last-child {
				margin-right: 0;
			}

			&.active {
				a {
					color: #fff !important;
					background-color: $primaryColor !important;
					border-color: $primaryColor !important;

					&:hover {
						color: #fff !important;
						background-color: $primaryColor !important;
					}
				}
			}

			a {
				&:hover {
					color: #fff !important;
					background-color: $fourthColor !important;
				}

				&.page-link {
					border-radius: 4px;
					background-color: #fff;
					border: 1px solid #D8DCE9;
					font-size: 18px;
					color: $colorbbe;
					text-align: center;
					display: inline-block;
					width: 55px;
					font-weight: bold;
					height: 45px;
					line-height: 45px !important;
					padding: 0 !important;
					transition: all ease 0.3s;

					@include screen(mobile) {
						width: 30px;
						height: 30px;
						line-height: 30px !important;
					}

					@include screen(tablet) {
						width: 40px;
						height: 40px;
						line-height: 40px !important;
					}

					i {
						font-size: 24px;
						line-height: 45px;

						@include screen(mobile) {
							line-height: 30px;
						}

						@include screen(tablet) {
							line-height: 40px;
						}

					}

					&:focus,
					&:active,
					&:hover {
						outline: none;
						border-color: $fourthColor;
						background-color: $fourthColor;
						box-shadow: none !important;
					}
				}
			}
		}
	}
}














#header-one {
	margin-bottom: 30px;

	@include screen(mobile) {
		display: none;
	}

	@include screen(tablet) {
		display: none;
	}

	.header-top {
		padding: 15px 0;

		.item {
			.item-container {

				.lang-select {
					display: inline-block;
					float: left;
					margin-right: 10px;

					.bootstrap-select {
						width: auto;
						display: inline-block;
						border: 2px solid #ddd;
						border-radius: 4px;
					}
				}

				.slogan {
					display: inline-block;
					float: left;
					color: $textColor;
					font-weight: 400;
					line-height: 30px;
					font-size: 13px;
				}


				.phone {
					overflow: hidden;

					.phone-title {
						display: inline-block;
						float: left;
						margin-right: 5px;
						line-height: 30px;
						color: $textColor;
					}

					.phone-number {
						display: inline-block;
						float: left;
						line-height: 30px;

						a {
							display: inline-block;
							font-size: 18px;
							line-height: 30px;
							font-weight: 500;
							color: $primaryColor;
							transition: all 300ms ease;

							&:hover {
								color: $primaryLightColor;
							}
						}
					}
				}

				.social-links {
					display: block;
					line-height: 30px;
					text-align: right;

					ul {
						display: inline-block;

						li {
							float: left;
							margin-right: 10px;

							a {
								font-size: 24px;
								color: $textColor;
								transition: all 300ms ease;

								&:hover {
									color: $primaryColor;
								}
							}

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}

			}
		}
	}

	.header-bottom {
		.item {
			.item-container {
				.logo {
					a {
						display: inline-block;

						img {
							max-width: 80%;
						}
					}
				}

				.menu-area {
					margin-top: 17px;

					.dropdown-toggle::after {
						content: '\f2f9';
						font-family: Material-Design-Iconic-Font;
						border: 0;
						vertical-align: inherit;
					}

					.dropdown-menu {
						font-size: 14px;
					}

					.navbar {
						padding: 0;
						display: inline-block;
						float: right;

						.navbar-nav {
							.nav-link {
								font-weight: 500;
								font-size: 15px;
								color: #000;
							}
						}
					}
				}
			}
		}
	}
}

#slider-one-area {
	.slider-item {
		.item-container {

			&.back-image {
				&::before {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					background-color: rgba(255, 255, 255, 0.4);
				}
			}

			.text-container {
				height: 600px;
				position: relative;

				.slider-text-center {
					@include center(vertical);

					.slider-title {
						font-size: 48px;
						line-height: 60px;
						color: #000;
						margin-bottom: 30px;

						@include screen(mobile) {
							font-size: 28px;
						}

						strong {
							font-weight: bold;
							font-size: 48px;
							line-height: 60px;

							@include screen(mobile) {
								font-size: 28px;
							}
						}
					}

					.slider-desc {
						font-size: 18px;
						line-height: 32px;
						color: #000;
						margin-bottom: 30px;

						@include screen(mobile) {
							font-size: 15px;
						}
					}

					.slider-links {
						a {
							margin-right: 15px;

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}

	.slick-dots {
		bottom: 20px;

		li {
			button {
				border: 4px solid $primaryColor;
				border-radius: 50px;
				@include opacity(0.5);
				transition: all 300ms ease;

				&:before {
					display: none;
				}
			}

			&.slick-active {
				button {
					@include opacity(1);
				}
			}
		}
	}
}

#about-us {
	margin: 60px 0;

	.picture {
		@include screen(tablet) {
			margin-bottom: 25px;
		}

		border-radius: 3px;

		img {
			box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.256142);
			border-radius: 3px;

			@include screen(mobile) {
				margin-bottom: 20px;
			}
		}
	}

	.text-area {
		p {
			margin-bottom: 10px;
			font-size: 14px;
			line-height: 22px;
			color: $textColor;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}


#we-services {
	padding: 90px 0;
	position: relative;

	@include screen(mobile) {
		padding: 30px 0;
	}

	.big-title-area {
		div {
			color: #fff;
		}
	}

	&::before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: $secondColor;
		@include opacity(0.93);
	}
}





#slider-two-area {
	#slider-two {
		margin-top: 30px;
		margin-bottom: 50px;

		.slick-dots {
			bottom: -30px;

			li {
				button {
					border: 4px solid $white;
					border-radius: 50px;
					@include opacity(0.5);
					transition: all 300ms ease;

					&:before {
						display: none;
					}
				}

				&.slick-active {
					button {
						@include opacity(1);
					}
				}
			}
		}

		button {
			&.slick-arrow {
				width: 48px;
				height: 48px;
				border: 2px solid #D8DCE9;
				background-color: #FFFFFF;
				border-radius: 3px;
				transition: all ease .3s;

				&:hover,
				&:focus,
				&:active {
					border-color: $primaryLightColor;
					transition: all ease .3s;

					&::before {
						transition: all ease .3s;
						color: $primaryLightColor;
					}

				}

				&::before {
					color: $primaryColor;
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					font-size: 36px;
					transition: all ease .3s;
				}
			}

			&.slick-prev {
				left: -90px;

				&::before {
					content: '\f2fa';

				}
			}

			&.slick-next {
				right: -90px;

				&::before {
					content: '\f2fb';
				}
			}
		}


		.slider-item {
			.item-container {
				&:hover {
					background-color: $primaryLightColor;
					transition: all ease .3s;
					border-color: $primaryLightColor;
					box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);
				}

				margin: 10px;
				text-align: center;
				padding: 20px;
				border-radius: 4px;
				box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);
				border:2px solid #fff;
				transition: all ease .3s;

				a {
					display: inline-block;
					width: 100%;
				}

				img {
					border-radius: 4px;
					display: block;
					margin: 0 auto;
					margin-bottom: 5px;
				}

				strong {
					display: block;
					min-height: 70px;
					max-height: 70px;
					overflow: hidden;
					position: relative;

					b {
						@include center(both);
						width: 100%;
						color: #fff;
						font-size: 18px;
						line-height: 24px;
						font-weight: 600;
						max-height: 70px;
						display: block;
						overflow: hidden;
					}
				}

				p {
					display: block;
					color: #fff;
					font-size: 14px;
					line-height: 22px;
					min-height: 60px;
					max-height: 60px;
					overflow: hidden;
					font-weight: 400;
				}
			}
		}
	}
}





#product-area {
	margin-bottom: 50px;
	padding-top: 60px;

	@include screen("mobile") {
		margin-bottom: 30px;
		padding-top: 30px;
	}
}




#slider-three-area {
	#slider-three {

		margin-bottom: 50px;

		.slick-dots {
			bottom: -30px;

			li {
				button {
					border: 4px solid $primaryColor;
					border-radius: 50px;
					@include opacity(0.5);
					transition: all 300ms ease;

					&:before {
						display: none;
					}
				}

				&.slick-active {
					button {
						@include opacity(1);
					}
				}
			}
		}

		button {
			&.slick-arrow {
				width: 48px;
				height: 48px;
				border: 2px solid #D8DCE9;
				background-color: #FFFFFF;
				border-radius: 3px;
				transition: all ease .3s;

				&:hover,
				&:focus,
				&:active {
					border-color: $primaryLightColor;
					transition: all ease .3s;

					&::before {
						transition: all ease .3s;
						color: $primaryLightColor;
					}

				}

				&::before {
					color: $primaryColor;
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					font-size: 36px;
					transition: all ease .3s;
				}
			}

			&.slick-prev {
				left: -90px;

				&::before {
					content: '\f2fa';

				}
			}

			&.slick-next {
				right: -90px;

				&::before {
					content: '\f2fb';
				}
			}
		}


		.slider-item {}
	}
}


.product-item-container {


	&:hover {

		transition: all ease .3s;
		box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);

	}

	margin: 15px;
	margin-top: 25px;
	text-align: center;
	padding: 20px;
	border-radius: 4px;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);
	transition: all ease .3s;
	position: relative;

	i {
		&.prop-single {
			position: absolute;
			top: 10px;
			line-height: 20px;
			padding: 0 5px;
			font-size: 12px;
			color: $white;
			font-style: normal;
			font-weight: 500;
			font-weight: bold;

			&.new {
				left: 10px;
				background-color: $primaryLightColor;
			}

			&.discount {
				right: 10px;
				background-color: $color321;
			}
		}
	}


	.prop {
		position: absolute;
		top: 10px;
		left: 10px;
		z-index: 55;

		i {

			line-height: 20px;
			padding: 0 5px;
			font-size: 12px;
			color: $white;
			font-style: normal;
			font-weight: bold;
			float: left;
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}

			&.new {

				background-color: $primaryLightColor;
			}

			&.discount {
				background-color: $color321;
			}
		}
	}

	a {
		display: inline-block;
		width: 100%;
	}

	img {
		border-radius: 4px;
		display: block;
		margin: 0 auto;
		margin-bottom: 5px;
	}

	strong {
		display: block;
		min-height: 70px;
		position: relative;

		b {
			color: #4A4A4A;
			font-size: 14px;
			line-height: 16px;
			font-weight: 500;
			max-height: 33px;
			overflow: hidden;
			@include center(both);
			width: 100%;
		}
	}

	span {
		display: block;
		text-align: center;
		font-size: 20px;
		line-height: 23px;
		color: $primaryLightColor;
		font-weight: bold;
	}


}

#slider-tab-cat {
	display: table;
	margin: 0 auto;
	border: none;
	margin-bottom: 25px;

	li {
		float: left;
		margin-right: 20px;
		margin-bottom: 15px;

		@include screen("mobile") {
			margin-bottom: 15px;
		}

		&:last-child {
			margin-right: 0;
		}

		a {
			padding: 0;
			border: none;
			color: $primaryColor;
			font-weight: 500;
			font-size: 14px;
			line-height: 36px;
			padding: 0 25px;
			background-color: $thirdColor;
			display: inline-block;
			border-radius: 18px;
			transition: all ease 0.3s;

			&.active {
				background-color: $fourthColor;
				color: #fff;
				transition: all ease 0.3s;
			}
		}
	}
}


#project-area {
	padding-top: 10px;
	margin-bottom: 30px;




	.see-link {
		margin-top: 35px;
	}
}

.blue-dots-class {
	.slick-dots {
		bottom: -30px;

		li {
			button {
				border: 4px solid $primaryColor;
				border-radius: 50px;
				@include opacity(0.5);
				transition: all 300ms ease;

				&:before {
					display: none;
				}
			}

			&.slick-active {
				button {
					@include opacity(1);
				}
			}
		}
	}
}




#slider-four-area {



	.filter-slider-one {


		.slider-item {}
	}
}



.post-project-item-container {
	height: 475px;

	&:hover {

		transition: all ease .3s;

		em {
			padding: 30px;
			transition: all ease .3s;
		}
	}

	margin: 15px;
	text-align: left;
	border-radius: 4px;
	transition: all ease .3s;



	.item-content {
		display: inline-block;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
		position: relative;
		transition: all ease .5s;

		&:hover {
			background: rgba(53, 80, 145, 0.743886);
			transition: all ease .5s;

			em {
				p {
					height: 300px;

					transition: all ease .3s;
				}
			}

			a {
				line-height: 85px;
				transition: all ease .3s;

				&:hover {
					i {
						margin-left: 15px;
					}
				}

				i {
					line-height: 87px;
					transition: all ease .3s;

				}
			}
		}

		em {
			font-style: normal;
			position: absolute;
			padding-left: 25px;
			bottom: 55px;
			transition: all ease .3s;

			strong {
				display: block;
				font-size: 24px;
				line-height: 26px;
				color: #fff;
				font-weight: 400;
				margin-bottom: 15px;
			}

			p {
				font-size: 14px;
				line-height: 26px;
				color: #fff;
				display: block;
				height: 0;
				max-height: 320px;
				overflow: hidden;
				transition: all ease .3s;

			}

		}

		a {
			font-size: 14px;
			line-height: 16px;
			color: #fff;
			display: inline-block;
			line-height: 70px;
			position: absolute;
			bottom: 0;
			transition: all ease .3s;
			left: 25px;

			i {
				font-weight: 500;
				font-size: 24px;
				line-height: 70px;
				color: #fff;
				margin-left: 10px;
				float: right;
				transition: all ease .3s;
			}
		}
	}



}




#event-area {
	margin-bottom: 50px;
	padding-top: 60px;

	@include screen("mobile") {
		margin-bottom: 30px;
		padding-top: 30px;
	}
}

#events-page-content {


	.event-container {



		border-radius: 4px;
		transition: all ease .3s;
		position: relative;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);
		margin-top: 50px;
		margin-bottom: 30px;

		.event-banner {
			position: relative;

			img {
				width: 100%;
			}

			.date {

				padding: 15px;
				text-align: center;
				color: #fff;
				position: absolute;
				transition: all ease .3s;
				border-radius: 4px;
				background-color: $color7f7;
				top: -50px;
				left: 20px;

				strong {
					display: block;
					font-size: 44px;
					line-height: 45px;
					font-weight: bold;
				}

				span {
					display: block;
					font-size: 20px;
					line-height: 23px;
					font-weight: 500;
				}

				b {
					display: block;
					font-size: 14px;
					line-height: 16px;
					font-weight: 400;
				}
			}




		}

		.title {
			display: block;
			text-align: center;
			min-height: 70px;
			max-height: 70px;
			overflow: hidden;
			position: relative;

			span {
				display: block;
				font-size: 18px;
				line-height: 22px;
				color: $black;
				font-weight: 600;
				position: absolute;
				@include center(both);
				width: 90%;
				transition: all ease .3s;

			}
		}

		.text {
			padding: 0 20px 25px 20px;


			.bottom {
				display: inline-block;
				width: 100%;
				padding: 20px 0;

				.location {
					width: 60px;
					float: left;
					height: 40px;
					text-align: center;

					i {
						background-color: #F9FAFC;
						display: inline-block;
						width: 40px;
						height: 40px;
						text-align: center;
						color: #7E7E7E;
						border-radius: 4px;
						line-height: 40px;
						font-size: 24px;
						font-weight: 500;
						transition: all ease .3s;
					}

				}

				.adress {
					width: calc(100% - 60px);
					float: left;
					text-transform: capitalize;
					min-height: 40px;
					color: #9B9B9B;
					font-size: 14px;
					line-height: 17px;
					font-weight: 500;
					padding-top: 2px;
				}



			}

			.link {
				display: block;
				width: 100%;
				text-align: center;

				a {
					font-size: 14px;
					line-height: 16px;
					color: #9B9B9B;
					padding-bottom: 5px;
					position: relative;
					font-weight: 500;
					transition: all ease .3s;

					&::before {
						content: "";
						width: 100%;
						height: 2px;
						background-color: #D8DCE9;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						z-index: 2;


					}

					&::after {
						content: "";
						width: 0;
						height: 2px;
						background-color: $primaryColor;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						z-index: 3;
						transition: all ease 0.3s;

					}
				}
			}
		}

		&:hover {

			transition: all ease .3s;
			box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);

			.title {
				span {
					color: $primaryColor;
					transition: all ease .3s;
				}
			}

			.date {

				background-color: $primaryColor !important;
				transition: all ease .3s;

			}

			.location {
				i {
					background-color: $primaryLightColorSoft !important;
					color: $primaryLightColor !important;
					transition: all ease .3s;
				}
			}

			.link {
				a {
					color: $color7f7 !important;
					transition: all ease .3s;

					&:after {
						transition: all ease .3s;
						width: 100%;
					}
				}
			}

		}


	}






}



#slider-event-area {
	#slider-event {

		margin-bottom: 50px;

		button {
			&.slick-arrow {
				width: 48px;
				height: 48px;
				border: 2px solid #D8DCE9;
				background-color: #FFFFFF;
				border-radius: 3px;
				transition: all ease .3s;

				&:hover,
				&:focus,
				&:active {
					border-color: $primaryLightColor;
					transition: all ease .3s;

					&::before {
						transition: all ease .3s;
						color: $primaryLightColor;
					}

				}

				&::before {
					color: $primaryColor;
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					font-size: 36px;
					transition: all ease .3s;
				}
			}

			&.slick-prev {
				left: -90px;

				&::before {
					content: '\f2fa';

				}
			}

			&.slick-next {
				right: -90px;

				&::before {
					content: '\f2fb';
				}
			}
		}


		.slider-item {
			.item-container {


				margin: 15px;
				margin-top: 25px;
				margin-bottom: 25px;
				padding: 20px;
				border-radius: 4px;
				transition: all ease .3s;
				position: relative;
				box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);

				.title {
					display: block;
					text-align: center;
					min-height: 70px;
					max-height: 70px;
					overflow: hidden;
					position: relative;

					span {
						display: block;
						font-size: 18px;
						line-height: 22px;
						color: $black;
						font-weight: 600;
						position: absolute;
						@include center(both);
						width: 100%;
						transition: all ease .3s;
						max-height: 45px;
						overflow: hidden;

						@include screen(mobile) {
							max-height: 65px;
						}

						@include screen(tablet) {
							max-height: 65px;
						}

					}
				}

				.text {
					.top {
						display: inline-block;
						width: 100%;
						padding-bottom: 20px;
						border-bottom: 1px solid #f6f6f6;

						.date {
							width: 60px;
							float: left;
							text-align: center;
							color: #9B9B9B;
							position: relative;
							min-height: 135px;
							transition: all ease .3s;

							strong {
								display: block;
								font-size: 44px;
								line-height: 50px;
								font-weight: bold;
							}

							span {
								display: block;
								font-size: 20px;
								line-height: 23px;
								font-weight: 500;
							}

							b {
								display: block;
								font-size: 14px;
								line-height: 16px;
								font-weight: 400;
							}
						}

						.text {
							width: calc(100% - 60px);
							float: left;
							position: relative;
							min-height: 135px;
							max-height: 135px;
							overflow: hidden;

							p {
								display: block;
								color: #9B9B9B;
								font-size: 14px;
								line-height: 20px;
								max-height: 100px;

								@include screen(tablet) {
									max-height: 110px;
								}

								@include screen(mobile) {
									max-height: 110px;
								}
							}

						}

						.centerF {
							position: absolute;
							@include center(vertical);
						}
					}

					.bottom {
						display: inline-block;
						width: 100%;
						padding: 20px 0;

						.location {
							width: 60px;
							float: left;
							height: 40px;
							text-align: center;

							i {
								background-color: #F9FAFC;
								display: inline-block;
								width: 40px;
								height: 40px;
								text-align: center;
								color: #7E7E7E;
								border-radius: 4px;
								line-height: 40px;
								font-size: 24px;
								font-weight: 500;
								transition: all ease .3s;
							}

						}

						.adress {
							width: calc(100% - 60px);
							float: left;
							text-transform: capitalize;
							min-height: 40px;
							color: #9B9B9B;
							font-size: 14px;
							line-height: 17px;
							font-weight: 500;
							padding-top: 2px;
						}



					}

					.link {
						display: block;
						width: 100%;
						text-align: center;

						a {
							font-size: 14px;
							line-height: 16px;
							color: #9B9B9B;
							padding-bottom: 5px;
							position: relative;
							font-weight: 500;
							transition: all ease .3s;

							&::before {
								content: "";
								width: 100%;
								height: 2px;
								background-color: #D8DCE9;
								position: absolute;
								bottom: 0;
								left: 0;
								right: 0;
								z-index: 2;


							}

							&::after {
								content: "";
								width: 0;
								height: 2px;
								background-color: $primaryLightColor;
								position: absolute;
								bottom: 0;
								left: 0;
								right: 0;
								margin-left: auto;
								margin-right: auto;
								z-index: 3;
								transition: all ease 0.3s;

							}
						}
					}
				}

				&:hover {

					transition: all ease .3s;
					box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);

					.title {
						span {
							color: $primaryColor;
							transition: all ease .3s;
						}
					}

					.date {

						color: $primaryLightColor !important;
						transition: all ease .3s;

					}

					.location {
						i {
							background-color: $primaryLightColorSoft !important;
							color: $primaryLightColor !important;
							transition: all ease .3s;
						}
					}

					.link {
						a {
							color: $primaryLightColor !important;
							transition: all ease .3s;

							&:after {
								transition: all ease .3s;
								width: 100%;
							}
						}
					}

				}


			}
		}
	}
}






.events-detail-content {
	.events-detail {
		padding: 20px 30px;
		border: 1px solid $thirdColor;
		border-radius: 4px;
		padding-bottom: 5px;
		margin-bottom: 20px;
		display: inline-block;
		width: 100%;

		.event-title {
			font-size: 18px;
			display: block;
			line-height: 22px;
			font-weight: 600;
			padding-bottom: 5px;
		}

		.event-time {
			display: inline-block;
			width: 100%;
			margin-top: 20px;
			padding-bottom: 25px;
			border-bottom: 1px solid $color6f6;

			.time-field {
				display: inline-block;
				width: 100%;
				background-color: $primaryColor;
				border-radius: 4px;
				padding: 20px;
				position: relative;
				margin: 12px 0;

				&:after {
					content: "\f32f";
					opacity: 0.1;
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					color: #fff;
					font-size: 72px;
					position: absolute;
					@include center(vertical);
					right: -10px;

				}

				.date {
					.day-number {
						float: left;

						strong {
							font-size: 48px;
							line-height: 42px;
							color: #fff;
							display: inline-block;
							font-weight: 600;

						}
					}

					.day-text {
						padding-left: 15px;
						float: left;

						span {
							font-size: 20px;
							line-height: 23px;
							color: #fff;
							display: block;
							font-weight: 500;
						}

						b {
							font-size: 14px;
							line-height: 16px;
							color: #fff;
							font-weight: 400;
						}
					}
				}

				i {
					&.start-and-finish {
						position: absolute;
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						text-align: center;
						font-style: normal;
						line-height: 23px;

						b {
							font-size: 12px;
							line-height: 24px;
							color: #fff;
							display: inline-block;
							font-weight: 500;
							padding: 0 25px;
							border-radius: 11.5px;

							i {
								margin-right: 5px;
								display: inline-block;
								font-size: 14px;
								line-height: 23px;
							}
						}

						&.top {
							top: -12px;

							b {
								background-color: $fourthColor;
							}

						}

						&.bottom {
							bottom: -12px;

							b {
								background-color: $colorbbe;
							}
						}
					}
				}
			}
		}

		.event-adress-text {
			position: relative;
			padding-left: 50px;
			display: inline-block;
			width: 100%;
			min-height: 70px;
			overflow: hidden;

			span {
				font-size: 14px;
				line-height: 16px;
				color: $colore7e;
				font-weight: 500;
				@include center(vertical);
			}

			i {
				font-size: 24px;
				line-height: 40px;
				font-weight: 500;
				display: inline-block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				background-color: $primaryColor;
				text-align: center;
				color: #fff;
				border-radius: 4px;
				position: absolute;
				top: 0;
				left: 0;
				@include center(vertical);
			}
		}

		.way-direction {
			padding: 10px 0;

			ul {
				display: inline-block;
				width: calc(100% + 20px);

				li {
					float: left;
					width: calc(100% / 3);
					padding-right: 20px;

					@include screen(mobile) {
						width: 100%;
						margin-bottom: 10px;
					}

					&:first-child {
						padding-left: 0;

						a {
							i {
								transform: rotate(30deg);
							}
						}

					}

					a {
						&:hover {
							transition: all ease 0.3s;

							span {
								color: #fff;
							}

							background-color: $fourthColor;
						}

						display: inline-block;
						width: 100%;
						text-align: center;
						line-height: 55px;
						border: 1px solid $colorce9;
						transition: all ease 0.3s;
						border-radius: 4px;

						span {
							font-size: 13px;
							font-weight: 500;
							color: $primaryColor;
							line-height: 25px;
							transition: all ease 0.3s;
							display: inline-block;

							i {
								font-size: 24px;
								float: left;
								margin-right: 10px;

							}
						}
					}
				}
			}
		}

		.event-content {
			padding-top: 10px;
			padding-bottom: 15px;

			.title {
				display: block;
				padding: 10px 0;

				span {
					font-size: 18px;
					line-height: 22px;
					font-weight: 500;
				}
			}

			p {
				font-size: 14px;
				line-height: 20px;
				color: $colore7e;
			}
		}




	}
}




.event-detail-page-ebent-poster {
	img {
		border-radius: 4px;
	}
}


#news-area {
	margin-bottom: 50px;
	padding-top: 60px;

	@include screen("mobile") {
		margin-bottom: 30px;
		padding-top: 30px;
	}
}


#articles-detail-page-content {}

.articles-detail-content {
	.blog-single-item-container {
		margin: 35px 0px 35px 25px;

		&.post-item {
			margin-bottom: 45px;

			.banner {
				max-height: 250px;
				overflow: hidden;
			}
		}

		&.post-item-style-2 {
			margin-bottom: 45px;

			.banner {
				max-height: 250px;
				overflow: hidden;
			}

		}
	}
}

#slider-news-area {
	#slider-news {
		margin-bottom: 50px;
		margin-top: -20px;

		button {
			&.slick-arrow {
				width: 48px;
				height: 48px;
				border: 2px solid #D8DCE9;
				background-color: #FFFFFF;
				border-radius: 3px;
				transition: all ease .3s;

				&:hover,
				&:focus,
				&:active {
					border-color: $primaryLightColor;
					transition: all ease .3s;

					&::before {
						transition: all ease .3s;
						color: $primaryLightColor;
					}

				}

				&::before {
					color: $primaryColor;
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					font-size: 36px;
					transition: all ease .3s;
				}
			}

			&.slick-prev {
				left: -90px;

				&::before {
					content: '\f2fa';

				}
			}

			&.slick-next {
				right: -90px;

				&::before {
					content: '\f2fb';
				}
			}
		}


		.slider-item {}
	}
}

#news-page-content {
	.news-page-list-style-1 {
		.news-post-wrapper {
			margin: 0;
			margin-bottom: 30px;

			&:hover {
				margin-top: 0 !important;
			}
		}
	}

	.news-page-list-style-2 {
		.news-post-wrapper {
			margin: 0;
			margin-bottom: 30px;

			&:hover {
				margin-top: 0 !important;
			}
		}
	}

}

.news-post-wrapper {

	margin: 40px 15px 25px 15px;

	border-radius: 4px;
	transition: all ease .3s;
	position: relative;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);

	&::after {
		content: "";
		width: 0;
		height: 2px;
		background-color: $primaryColor;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		transition: all ease .3s;
	}

	.banner {
		display: inline-block;
		width: 100%;
		background-color: #ECECEC;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		overflow: hidden;

		img {
			transition: all ease .3s;
		}
	}

	.text {
		display: inline-block;
		width: 100%;
		padding: 20px 30px 30px 30px;

		.title {
			display: inline-block;
			width: 100%;
			min-height: 47px;
			max-height: 47px;
			overflow: hidden;
			position: relative;
			margin-bottom: 5px;

			a {
				font-size: 18px;
				line-height: 22px;
				color: $primaryColor;
				display: block;
				max-height: 45px;
				overflow: hidden;
				@include center(vertical);
				font-weight: 600;
				transition: all ease .3s;

			}
		}

		p {
			color: $textColor;
			display: block;
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 20px;
			max-height: 88px;
			overflow: hidden;
		}

		a {
			&.read {
				font-size: 14px;
				line-height: 22px;
				display: inline-block;
				font-weight: 500;
				position: relative;

				&::after {
					content: "";
					width: 100%;
					height: 2px;
					background-color: $primaryColor;
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}
		}


	}


	&:hover {

		transition: all ease .3s;
		margin-top: 25px;
		box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);

		&::after {
			width: 100%;
			transition: all ease .3s;
		}

		.title {
			a {
				color: $color7f7;
				transition: all ease .3s;
			}
		}

		.banner {
			img {
				transform: scale(1.1);
				transition: all ease .3s;
			}
		}

	}


}



.news-post-wrapper-2 {


	margin-bottom: 30px;
	border-radius: 4px;
	transition: all ease .3s;
	position: relative;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);
	display: inline-block;
	width: 100%;

	&::after {
		content: "";
		width: 0;
		height: 2px;
		background-color: $color7f7;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		transition: all ease .3s;
	}

	.banner {
		display: inline-block;
		width: 50%;
		border-radius: 4px;
		overflow: hidden;
		float: left;

		img {
			transition: all ease .3s;
		}
	}

	.text {
		display: inline-block;
		width: 50%;
		padding: 15px;
		float: left;

		.title {
			display: inline-block;
			width: 100%;
			min-height: 47px;
			max-height: 47px;
			overflow: hidden;
			position: relative;
			margin-bottom: 5px;

			a {
				font-size: 18px;
				line-height: 22px;
				color: $primaryColor;
				display: block;
				max-height: 45px;
				overflow: hidden;
				@include center(vertical);
				font-weight: 600;
				transition: all ease .3s;

			}
		}

		p {
			color: $textColor;
			display: block;
			font-size: 14px;
			line-height: 22px;
			max-height: 50px;
			overflow: hidden;
		}

		span {
			&.date {
				font-size: 11px;
				display: inline-block;
				line-height: 15px;
				color: $colorb9b;
				padding: 5px 7px;
				border-radius: 4px;
				background-color: $colorcec;
				font-weight: 500;
				margin: 10px 0;
				display: inline-block;
				margin-right: 20px;
			}
		}

		a {
			&.read {
				font-size: 14px;
				line-height: 22px;
				display: inline-block;
				font-weight: 500;
				position: relative;

				&::after {
					content: "";
					width: 100%;
					height: 2px;
					background-color: $primaryColor;
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}
		}


	}


	&:hover {

		transition: all ease .3s;
		box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);

		&::after {
			width: 100%;
			transition: all ease .3s;
		}

		.title {
			a {
				color: $color7f7;
				transition: all ease .3s;
			}
		}

		a {
			&.read {
				color: $color7f7;
				transition: all ease .3s;

				&:after {
					background-color: $color7f7;
					transition: all ease .3s;
				}
			}
		}

		.banner {
			img {
				transform: scale(1.1);
				transition: all ease .3s;
			}
		}

	}


}



.news-post-wrapper-3 {


	margin-bottom: 30px;
	border-radius: 4px;
	transition: all ease .3s;
	position: relative;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);
	display: inline-block;
	width: 100%;

	&::after {
		content: "";
		width: 0;
		height: 2px;
		background-color: $color7f7;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		transition: all ease .3s;
	}

	.banner {
		display: inline-block;
		width: 100%;
		border-radius: 4px;
		overflow: hidden;

		img {
			transition: all ease .3s;
		}
	}

	.text {
		display: inline-block;
		width: 100%;
		padding: 15px;

		.title {
			display: inline-block;
			width: 100%;
			min-height: 47px;
			max-height: 47px;
			overflow: hidden;
			position: relative;
			margin-bottom: 5px;

			a {
				font-size: 18px;
				line-height: 22px;
				color: $primaryColor;
				display: block;
				max-height: 48px;
				overflow: hidden;
				@include center(vertical);
				font-weight: 600;
				transition: all ease .3s;

			}
		}

		p {
			color: $textColor;
			display: block;
			font-size: 14px;
			line-height: 22px;
			max-height: 50px;
			overflow: hidden;
		}

		span {
			&.date {
				font-size: 11px;
				display: inline-block;
				line-height: 15px;
				color: $colorb9b;
				padding: 5px 7px;
				border-radius: 4px;
				background-color: $colorcec;
				font-weight: 500;
				margin: 10px 0;
				display: inline-block;
				margin-right: 20px;
			}
		}

		a {
			&.read {
				font-size: 14px;
				line-height: 22px;
				display: inline-block;
				font-weight: 500;
				position: relative;

				&::after {
					content: "";
					width: 100%;
					height: 2px;
					background-color: $primaryColor;
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}
		}


	}


	&:hover {

		transition: all ease .3s;
		box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);

		&::after {
			width: 100%;
			transition: all ease .3s;
		}

		.title {
			a {
				color: $color7f7;
				transition: all ease .3s;
			}
		}

		a {
			&.read {
				color: $color7f7;
				transition: all ease .3s;

				&:after {
					background-color: $color7f7;
					transition: all ease .3s;
				}
			}
		}

		.banner {
			img {
				transform: scale(1.1);
				transition: all ease .3s;
			}
		}

	}


}






#team-area {
	margin-bottom: 50px;
	padding-top: 60px;

	@include screen("mobile") {
		margin-bottom: 30px;
		padding-top: 30px;
	}
}

#slider-team-area {
	#slider-team {
		margin-bottom: 50px;

		button {
			&.slick-arrow {
				width: 48px;
				height: 48px;
				border: 2px solid #D8DCE9;
				background-color: #FFFFFF;
				border-radius: 3px;
				transition: all ease .3s;

				&:hover,
				&:focus,
				&:active {
					border-color: $color7f7;
					transition: all ease .3s;

					&::before {
						transition: all ease .3s;
						color: $color7f7;
					}

				}

				&::before {
					color: $primaryColor;
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					font-size: 36px;
					transition: all ease .3s;
				}
			}

			&.slick-prev {
				left: -90px;

				&::before {
					content: '\f2fa';

				}
			}

			&.slick-next {
				right: -90px;

				&::before {
					content: '\f2fb';
				}
			}
		}


		.slider-item {}
	}
}



.team-single-item-container {


	margin: 40px 15px 10px 15px;
	border-radius: 4px;
	transition: all ease .3s;
	position: relative;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);
	position: relative;
	padding-bottom: 110px;

	img {
		@include screen(mobile) {
			display: block;
			margin: 0 auto;
		}
	}

	.text {
		background-color: $white;
		text-align: center;
		display: inline-block;
		width: 100%;
		padding: 35px 20px;
		position: absolute;
		bottom: 0;
		transition: all ease 0.3s;

		strong {
			display: block;
			font-size: 18px;
			line-height: 21px;
			color: $primaryColor;
			transition: all ease .2s;

		}

		span {
			display: block;
			font-size: 14px;
			line-height: 22px;
			color: $textColor;

		}

		ul {
			height: 0;
			overflow: hidden;
			transition: all ease 0.3s;

			li {
				margin-right: 5px;
				display: inline-block;

				&:last-child {
					margin-right: 0;
				}

				a {
					float: left;
					font-size: 24px;
					color: #D6D6D6;
					transition: all ease 0.3s;

					&:hover {
						color: $textColor;
						transition: all ease 0.3s;
					}
				}

			}
		}

	}

	&:hover {

		transition: all ease .3s;
		margin-top: 25px;

		.text {
			strong {
				color: $color7f7;
				transition: all ease .2s;
			}

			ul {
				margin-top: 20px;
				height: 30px;
				transition: all ease .2s;
			}
		}

	}


}





#blog-area {
	margin-bottom: 50px;
	padding-top: 60px;

	@include screen("mobile") {
		padding-top: 30px;
	}
}


#slider-blog-area {
	#slider-blog {
		margin-top: 10px;
		margin-bottom: 50px;

		button {
			&.slick-arrow {
				width: 48px;
				height: 48px;
				border: 2px solid #D8DCE9;
				background-color: #FFFFFF;
				border-radius: 3px;
				transition: all ease .3s;

				&:hover,
				&:focus,
				&:active {
					border-color: $color7f7;
					transition: all ease .3s;

					&::before {
						transition: all ease .3s;
						color: $color7f7;
					}

				}

				&::before {
					color: $primaryColor;
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					font-size: 36px;
					transition: all ease .3s;
				}
			}

			&.slick-prev {
				left: -90px;

				&::before {
					content: '\f2fa';

				}
			}

			&.slick-next {
				right: -90px;

				&::before {
					content: '\f2fb';
				}
			}
		}


		.slider-item {}
	}
}


.blog-single-item-container {


	margin: 35px 25px 35px 25px;
	transition: all ease .3s;
	position: relative;
	display: inline-block;


	.text {
		display: inline-block;
		width: 50%;
		position: relative;
		margin-top: -15px;
		float: left;

		@include screen("mobile") {
			width: 100%;
		}

		&:before {
			content: "";
			position: absolute;
			background-color: #ECECEC;
			top: -20px;
			left: -20px;
			bottom: -20px;
			right: -20px;
			border-radius: 4px;
			transition: all ease .3s;

		}








		.innerC {
			position: relative;
			z-index: 5;
			padding: 36px 30px;
			background-color: $white;
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);
			border-radius: 4px;


		}

		.date {
			font-size: 13px;
			display: block;
			color: #bfbfbf;
			line-height: 15px;
			font-weight: 500;
			margin-bottom: 10px;

			i {
				color: #bfbfbf;
				font-size: 13px;
				margin-right: 5px;

			}
		}

		.title {
			display: block;
			min-height: 45px;
			max-height: 45px;
			position: relative;
			overflow: hidden;
			margin-bottom: 5px;

			strong {
				font-size: 18px;
				display: block;
				line-height: 22px;
				color: $primaryColor;
				max-height: 45px;
				overflow: hidden;
				transition: all ease .3s;
				@include center(vertical);
			}
		}

		.content {
			p {
				display: block;
				font-size: 14px;
				line-height: 22px;
				color: #7E7E7E;
				min-height: 93px;
				max-height: 93px;
				overflow: hidden;
				font-weight: 400;
				margin-bottom: 10px;

			}

			a {
				display: inline-block;
				font-size: 14px;
				line-height: 23px;
				color: #7e7e7e;
				padding-bottom: 4px;
				position: relative;
				font-weight: 500;

				&:before {
					content: "";
					width: 100%;
					height: 2px;
					background-color: #7e7e7e;
					position: absolute;
					bottom: 0;
					left: 0;
				}

			}
		}
	}

	.banner {
		@include screen("mobile") {
			width: 100%;
		}

		width: 50%;
		float: left;
		position: relative;
		z-index: 5;
		box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);

		img {
			border-bottom-right-radius: 4px;
			border-top-right-radius: 4px;

			@include screen("mobile") {
				display: none;
			}
		}
	}


	&:hover {
		transition: all ease .3s;

		.text {
			&::before {
				background-color: $primaryColor;
				transition: all ease .3s;
			}
		}

		.title {
			strong {
				color: $color7f7;
				transition: all ease .3s;
			}
		}

	}


}




#comment-area {
	padding-top: 50px;

	@include screen("mobile") {
		padding-top: 30px;
	}
}

#slider-comment-area {

	#slider-comment {
		margin-bottom: 10px;
		margin-top: 30px;
		padding-bottom: 40px;

		button {
			&.slick-arrow {
				width: 48px;
				height: 48px;
				border: 2px solid #D8DCE9;
				background-color: #FFFFFF;
				border-radius: 3px;
				transition: all ease .3s;

				&:hover,
				&:focus,
				&:active {
					border-color: $primaryLightColor;
					transition: all ease .3s;

					&::before {
						transition: all ease .3s;
						color: $primaryLightColor;
					}

				}

				&::before {
					color: $primaryColor;
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					font-size: 36px;
					transition: all ease .3s;
				}
			}

			&.slick-prev {
				left: -90px;

				&::before {
					content: '\f2fa';

				}
			}

			&.slick-next {
				right: -90px;

				&::before {
					content: '\f2fb';
				}
			}
		}

		.slick-center {
			.slider-item {
				opacity: 1 !important;
				transition: all ease .3s;

				.item-container {
					transition: all ease .3s;
					box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);
				}
			}

		}

		.slider-item {
			opacity: 0.35;
			transition: all ease .3s;

			@include screen(tablet) {
				opacity: 1;
			}

			.item-container {

				border: 1px solid #eeeeee;
				margin: 25px 15px 25px 15px;
				border-radius: 4px;
				transition: all ease .3s;
				position: relative;
				padding: 25px 30px 30px 30px;
				background-color: $white;

				&:hover {}


				.text {

					p {
						font-size: 14px;
						line-height: 20px;
						color: #7e7e7e;
						display: block;

					}

					display: block;
					margin-bottom: 20px;

				}

				.bottom {
					display: inline-block;
					width: 100%;

					.left {
						width: 70px;
						float: left;
						border-radius: 50%;
						height: 70px;
						box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);
						overflow: hidden;

						img {

							display: block;
							margin: 0 auto;

						}
					}

					.title {
						width: calc(100% - 70px);
						float: left;
						position: relative;
						min-height: 70px;

						.name {
							width: 100%;
							@include center(both);
							padding-left: 10px;

							strong {
								display: block;
								color: #4A4A4A;
								font-size: 18px;
								line-height: 21px;
								margin-bottom: 3px;
							}

							span {
								display: block;
								font-size: 14px;
								font-weight: 300;
								line-height: 17px;
							}
						}
					}
				}


			}
		}



		.slick-dots {
			bottom: 0;

			li {
				button {
					border: 4px solid $primaryColor;
					border-radius: 50px;
					@include opacity(0.5);
					transition: all 300ms ease;

					&:before {
						display: none;
					}
				}

				&.slick-active {
					button {
						@include opacity(1);
					}
				}
			}
		}
	}
}






#reference-area {
	padding-top: 120px;
	padding-bottom: 50px;

	@include screen("mobile") {
		padding-top: 30px;
	}
}


#slider-reference-area {
	#slider-reference {
		margin-bottom: 30px;
		padding-bottom: 30px;

		.slick-dots {
			bottom: 0;

			li {
				button {
					border: 4px solid $primaryColor;
					border-radius: 50px;
					@include opacity(0.5);
					transition: all 300ms ease;
					width: 10px;
					height: 10px;

					&:before {
						display: none;
					}
				}

				&.slick-active {
					button {
						@include opacity(1);
					}
				}
			}
		}

		.slider-item {}
	}
}

.reference-logo-item-container {


	margin: 25px 15px 25px 15px;
	border-radius: 4px;
	transition: all ease .3s;
	position: relative;


	.logo {
		height: 120px;
		border: 1px solid #ccc;
		position: relative;
		transition: all ease .3s;
		border-radius: 4px;

		a {
			display: inline-block;
			width: 100%;
			height: 120px;
		}

		img {
			@include center(both);
		}

		strong {
			font-size: 14px;
			line-height: 18px;
			@include center(both);
			color: #7E7E7E;
			font-weight: 500;
			text-align: center;
		}
	}

	&:hover {

		transition: all ease .3s;

		.logo {

			transition: all ease .3s;
			box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);
			border-color: #fff;
		}


	}


}


.reference-logo-with-text-item-container {


	border-radius: 4px;
	transition: all ease .3s;
	position: relative;
	border: 1px solid #ccc;
	margin-bottom: 30px;

	.logo {
		height: 120px;

		position: relative;
		opacity: .5;
		transition: all ease .3s;


		img {
			@include center(both);
		}

		strong {
			font-size: 14px;
			line-height: 18px;
			@include center(both);
			color: #7E7E7E;
			font-weight: 500;
			text-align: center;
		}
	}

	.company-name {
		display: block;
		padding: 0 20px 20px 20px;
		text-align: center;

		span {
			font-size: 14px;
			line-height: 16px;
			color: $colore7e;
			border-top: 1px solid $colorce9;
			padding-top: 15px;
			display: block;
			font-weight: 500;
			max-width: 90%;
			margin: 0 auto;
			transition: all ease .3s;

		}
	}

	&:hover {

		transition: all ease .3s;
		box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);
		border-color: #fff;

		.logo {
			opacity: 1;
			transition: all ease .3s;

		}

		.company-name {
			span {
				color: $fourthColor;
				text-decoration: underline;
				transition: all ease .3s;
			}
		}


	}


}


#helper-area {

	background-color: #F3FBFE;

	.banner {
		margin-top: 70px;

		img {
			@include screen(mobile) {
				max-height: 250px;
			}
		}
	}

	.right {
		display: inline-block;
		width: 100%;
		height: 100%;
		position: relative;
	}

	.contact-text {
		text-align: center;
		padding: 70px 60px;
		display: block;
		box-shadow: 0px 1px 24px #B8D0D9;
		background-color: #FFFFFF;
		border-radius: 4px;
		@include center(both);
		width: 100%;

		@include screen("mobile") {
			position: inherit;
			padding: 30px 20px;
		}

		span {
			display: block;
			color: #7E7E7E;
			font-size: 36px;
			line-height: 44px;
			margin-bottom: 15px;
			font-weight: 400;

			@include screen(mobile) {
				font-size: 20px;
				line-height: 24px;
			}
		}

		p {
			display: block;
			font-size: 14px;
			line-height: 20px;
			color: #9B9B9B;
			margin-bottom: 15px;
			font-weight: 400;
		}

		a {
			font-size: 36px;
			line-height: 42px;
			color: $primaryColor;
			font-weight: 600;

			@include screen(mobile) {
				font-size: 20px;
				line-height: 24px;
			}
		}
	}
}



#footer {
	padding-top: 60px;
	margin-top: 50px;
	border-top: 1px solid #ccc;

	@include screen("mobile") {
		padding-top: 30px;
	}

	hr {
		margin-top: 60px;
		margin-bottom: 0;
		border-top: 1px solid #D8DCE9;
	}

	.footer-bottom {
		padding: 20px 0;

		img {
			width: 50px;
			display: block;
			margin: 0 auto;
		}
	}

	.about {
		@include screen("mobile") {
			text-align: center;
		}

		img {
			display: block;
			margin-bottom: 15px;

			@include screen("mobile") {
				width: 60%;
				margin: 0 auto;
				margin-bottom: 20px;
			}
		}

		p {
			display: block;
			font-size: 14px;
			line-height: 22px;
			color: #9b9b9b;
			margin-bottom: 45px;
		}

		span {
			display: block;
			font-size: 14px;
			line-height: 14px;
			color: #9B9B9B;

		}
	}

	.menu {
		strong {
			font-size: 20px;
			line-height: 23px;
			display: block;
			margin-bottom: 10px;
			margin-top: 20px;
		}

		ul {
			li {
				display: block;
				margin-bottom: 3px;

				a {
					color: #9B9B9B;
					font-size: 16px;
					line-height: 24px;
					display: block;
					transition: all ease 0.3s;

					&:hover {
						color: $primaryLightColor;
						transition: all ease 0.3s;
					}
				}
			}
		}
	}
}

#footer-style-2 {
	padding-top: 50px;

	background-color: $primaryColor;

	@include screen("mobile") {
		padding-top: 30px;
	}

	.f-logo-wrap {
		height: 100%;
		position: relative;
		min-height: 130px;

		.about {
			@include center(vertical);
		}
	}


	hr {
		margin-top: 60px;
		margin-bottom: 0;
		border-top: 1px solid #fff;
		opacity: .4;
	}

	.footer-bottom {
		padding: 20px 0;

		img {
			width: 50px;
			display: block;
			margin: 0 auto;
		}
	}

	.about {
		@include screen("mobile") {
			text-align: center;
		}

		img {
			display: block;
			margin-bottom: 15px;

			@include screen("mobile") {
				width: 60%;
				margin: 0 auto;
				margin-bottom: 20px;
			}
		}



		span {
			display: block;
			font-size: 14px;
			line-height: 14px;
			color: #fff;

		}
	}

	.menu {
		strong {
			font-size: 20px;
			line-height: 23px;
			display: block;
			margin-bottom: 10px;
			color: #fff;

			@include screen("mobile") {
				text-align: center;
				margin-top: 15px;
			}
		}

		ul {
			li {
				@include screen("mobile") {
					text-align: center;
				}

				display: block;
				margin-bottom: 3px;

				a {
					color: #fff;
					font-size: 13px;
					line-height: 24px;
					display: block;
					transition: all ease 0.3s;

					&:hover {
						color: $primaryLightColor;
						transition: all ease 0.3s;
					}
				}
			}
		}
	}

	.footer-adress {
		@include screen("mobile") {
			text-align: center;
		}

		strong {
			font-size: 20px;
			line-height: 23px;
			display: block;
			margin-bottom: 10px;
			color: #fff;

			@include screen("mobile") {
				margin-top: 15px;
			}
		}

		p {
			color: #fff;
			font-size: 13px;
			line-height: 24px;
			margin-bottom: 10px;
			font-weight: 400;
		}

		ul {
			li {

				margin-bottom: 5px;

				&:last-child {
					margin-bottom: 0;
				}

				span {
					font-size: 13px;
					line-height: 24px;
					color: #fff;
					display: inline-block;
					min-width: 70px;
				}

				a {
					font-size: 13px;
					line-height: 24px;
					color: #fff;
				}
			}
		}

		a {
			&.contact-link {
				float: left;
				margin-right: 10px;
				font-size: 13px;
				line-height: 34px;
				padding: 0 25px;
				display: inline-block;
				font-weight: 500;
				color: #fff;
				background-color: $fourthColor;
				border-radius: 17px;
				margin-top: 15px;

				@include screen("mobile") {
					float: none;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

	}


	ul {
		&.footer-social {
			padding: 25px 0;
			display: inline-block;
			width: 100%;

			li {
				float: left;
				margin-right: 15px;

				&:last-child {
					margin-right: 0;
				}

				a {
					font-size: 36px;
					line-height: 39px;
					color: #fff;
					transition: all ease 0.3s;

					&:hover {
						color: $fourthColor;
						transition: all ease 0.3s;
					}
				}
			}
		}
	}

	.anchor-area {
		padding: 25px 0;
		text-align: right;

		a {
			color: #fff;
			font-size: 20px;
			line-height: 40px;
			display: inline-block;
			font-weight: 400;

			i {
				font-size: 36px;
				line-height: 40px;
				color: #fff;
				margin-left: 10px;
				float: right;
			}
		}
	}

}

.footer-bottom-style-2 {
	padding: 15px 0;
	background-color: $fourthColor;

	span {
		font-size: 14px;
		line-height: 25px;
		display: block;
		font-weight: 400;
		color: #fff;
	}

	.logo-area {
		text-align: right;

		img {
			width: 30px;
		}
	}

}


.footer-style-2-top-e-news {
	background-color: $colorce9;
	margin-top: 50px;
	padding-top: 70px;

	@include screen("mobile") {
		padding-top: 30px;
	}

	.e-news-area {
		margin-bottom: -27px;

		span {
			font-size: 36px;
			line-height: 42px;
			color: $colora4a;
			display: block;
			text-align: center;
			font-weight: 500;
		}

		p {
			display: block;
			text-align: center;
			font-weight: 300;
			font-size: 14px;
			line-height: 15px;
			color: $colora4a;
			margin-bottom: 30px;
		}
	}

	.input-area {
		padding: 0 25px;
		position: relative;

		input {
			width: 100%;
			background: #FFFFFF;
			border-radius: 27px;
			padding-left: 15px;
			height: 54px;
			border: none;
			padding-right: 30px;
		}

		.btn-wrap {
			position: absolute;
			right: 0;
			bottom: 0;

			button {
				background-color: transparent;

				i {
					font-weight: 500;
					font-size: 24px;
					width: 54px;
					height: 54px;
					display: inline-block;
					line-height: 54px;
					color: #fff;
					background-color: $color940;
					border-radius: 50%;
					text-align: center;
				}
			}
		}
	}
}


.footer-style-3-top-e-news {
	background-color: $primaryColor;
	margin-top: 50px;
	padding-top: 30px;
	padding-bottom: 25px; 
	@include screen("mobile") {
		 
	}

	span{
		font-size: 24px;
		line-height: 28px;
		color: $colorce9;
		line-height: 55px;
		display: inline-block;
		font-weight: 500;
	}
	input{
		width: 100%;
		height: 55px;
		border-radius: 4px;
		font-size: 13px;
		line-height: 15px;
		padding-left: 20px;
		color: $colorb9b;
		border: none;
		font-weight: 500;
	}
	button{
		width: 100%;
		height: 55px;
		border-radius: 4px;
		@include screen(mobile){
			margin-top: 15px;
		}
	}

 
}

#footer-style-3{
	padding-top: 50px;
	@include screen(mobile){
		padding-top: 30px;
	}
	.f-logo-wrap{
		text-align: center;
		display: block;
		padding-bottom: 25px;
		border-bottom: 1px solid $colorce9;
		margin-bottom: 20px;
		img{
			width: 200px;
		}
	}
	span{
		font-size: 14px;
		line-height: 14px;
		color: $colorb9b;
		display: block;
		line-height: 40px;
		font-weight: 400;
	}
	.cloud-area{
		text-align: right;
		img{
			width: 40px;
		}
	}
}


#footer-style-4{
	padding-top: 60px;
	@include screen(mobile){
		padding-top: 30px;
	}
	.f-logo-wrap{
		text-align: left;
		display: block; 
		@include screen(mobile){ 
				text-align: center; 
			}
		img{
			width: 200px;
		}
	}
	hr{
		border-bottom: 1px solid $colorce9;
		margin-top: 25px;
		margin-bottom: 10px;
		
	}
	.menu-area{
		display: inline-block;
		width: 100%;
		
		ul{
			display: inline-block; 
			padding: 10px 0;
			margin-top: 20px;
			float: right;
			li{
				float: left;
				margin-right: 15px;
				@include screen(mobile){
					margin-bottom: 10px;
					margin-right: 18px;
				}
				&:last-child{
					margin-right: 0;
				}
				a{
					text-transform: uppercase;
					font-size: 15px;
					line-height: 18px;
					color: #000;
					font-weight: 500;
					transition: all ease 0.3s;
					padding-bottom: 2px;
					position: relative;
					&:after{
						content: "";
						width: 0;
						height: 1px;
						background-color: $fourthColor;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						margin-left: auto;
						margin-right: auto;
						transition: all ease 0.3s;
					}
					&:hover{
						color: $fourthColor;
						transition: all ease 0.3s;
						&:after{
							width: 100%;
							transition: all ease 0.3s;
						}
					}
				}
			}
		}
	}
	
	span{
		font-size: 14px;
		line-height: 14px;
		color: $colorb9b;
		display: block;
		line-height: 40px;
		font-weight: 400;
		@include screen(mobile){
				line-height: 20px;
				text-align: center;
				margin-bottom: 5px;
			}
	}
	.cloud-area{
		text-align: right;
		@include screen(mobile){
				text-align: center;
			}
		img{
			width: 40px;
		}
	}
}


#breadcrumb-style-1 {
	background-color: $primaryColor;
	padding: 20px 0;
	margin-bottom: 70px;

	@include screen(mobile) {
		margin-bottom: 30px;
	}

	@include screen(tablet) {
		margin-bottom: 30px;
	}

	ul {
		display: inline-block;
		width: 100%;

		li {
			float: left;
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}

			&:first-child {
				a {
					&::before {
						display: none;
					}
				}
			}

			a {
				font-size: 12px;
				line-height: 14px;
				color: $white;
				display: inline-block;

				&::before {
					content: "";
					width: 5px;
					height: 5px;
					border-radius: 50%;
					margin-right: 10px;
					display: inline-block;
					background-color: #fff;
					margin-top: 5px;
					float: left;
				}

			}
		}
	}

	strong {
		font-size: 18px;
		line-height: 21px;
		color: $white;
		display: block;
		text-transform: uppercase;
	}

}



#breadcrumb-style-3 {
	background-color: $fourthColor;
	padding: 20px 0;
	margin-bottom: 70px;
	position: relative;
	overflow: hidden;

	&:before {
		content: attr(data-title);
		font-size: 144px;
		color: $white;
		opacity: 0.1;
		@include center(both);
		font-weight: 600;
		width: 100%;
		text-align: center;

		@include screen(mobile) {
			font-size: 40px;
		}

		@include screen(tablet) {
			font-size: 60px;
		}
	}

	@include screen(mobile) {
		margin-bottom: 30px;
	}

	@include screen(tablet) {
		margin-bottom: 30px;
	}

	ul {
		display: inline-block;
		width: 100%;

		li {
			float: left;
			margin-right: 10px;
			line-height: 40px;

			&:last-child {
				margin-right: 0;
			}

			&:first-child {
				a {
					&::before {
						display: none;
					}
				}
			}

			a {
				font-size: 12px;
				line-height: 14px;
				color: $white;
				display: inline-block;

				&::before {
					content: "";
					width: 5px;
					height: 5px;
					border-radius: 50%;
					margin-right: 10px;
					display: inline-block;
					background-color: #fff;
					margin-top: 5px;
					float: left;
				}

			}
		}
	}

	a {
		&.contact-me {
			font-size: 13px;
			line-height: 40px;
			display: inline-block;
			padding: 0 40px;
			float: right;
			color: $white;
			border-radius: 27px;
			background-color: $primaryColor;
			font-weight: 500;
		}
	}

}




#breadcrumb-style-2 {
	background-color: $color1f1;
	padding: 60px 0 40px 0;
	margin-top: 50px;
	margin-bottom: 60px;

	@include screen(mobile) {
		margin-top: 30px;
		margin-bottom: 30px;
		padding: 15px 0;
	}

	@include screen(tablet) {
		margin-top: 30px;
		margin-bottom: 30px;
		padding: 15px 0;
	}

	ul {
		display: inline-block;
		width: 100%;

		li {
			float: left;
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}

			&:first-child {
				a {
					&::before {
						display: none;
					}
				}
			}

			a {
				font-size: 12px;
				line-height: 14px;
				color: $colore7e;
				display: inline-block;

				&::before {
					content: "";
					width: 5px;
					height: 5px;
					border-radius: 50%;
					margin-right: 10px;
					display: inline-block;
					background-color: #9B9B9B;
					margin-top: 5px;
					float: left;
				}

			}
		}
	}

	strong {
		font-size: 24px;
		line-height: 28px;
		color: $colora4a;
		display: block;
		text-transform: uppercase;
		margin-bottom: 15px;
		font-weight: bold;
		padding-top: 15px;
		position: relative;

		&:before {
			content: attr(data-title);
			position: absolute;
			opacity: 0.04;
			font-size: 48px;
			line-height: 56px;
			color: $colora4a;
			top: 0;
			left: 0;

		}
	}

}





#sidebar {
	.widget {
		padding: 20px;
		border-radius: 3px;

		&.p-none {
			padding: 0;
		}

		margin-bottom: 30px;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);
		transition: all ease 0.3s;

		&:hover {
			transition: all ease 0.3s;
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.33);
		}
	}

	.sidebar-help {
		background: #FFFFFF;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);

		span {
			color: #4a4a4a;
			font-size: 13px;
			line-height: 15px;
			display: block;
			margin-bottom: 10px;
			font-weight: 500;
		}

		p {
			font-size: 13px;
			line-height: 18px;
			color: #9b9b9b;
			display: block;
			margin-bottom: 20px;
		}

		ul {
			li {
				margin-bottom: 10px;
				display: inline-block;
				width: 100%;

				&:first-child {
					a {
						font-size: 18px;
						font-weight: 600;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}

				a {
					font-size: 13px;
					line-height: 25px;
					font-weight: 500;
					color: $primaryColor;

					i {
						color: $primaryLightColor;
						font-size: 24px;
						line-height: 25px;
						float: left;
						margin-right: 10px;
					}
				}
			}
		}
	}

	.title-style-1 {
		font-size: 13px;
		line-height: 15px;
		color: #4A4A4A;
		display: block;
		font-weight: 500;
	}

	.title-style-2 {
		font-size: 13px;
		line-height: 15px;
		color: #fff;
		display: block;
		font-weight: 500;
		padding: 20px;
		background-color: $primaryColor;
	}


	.title-style-3 {
		display: block;
		position: relative;
		margin-bottom: 10px;

		span {
			font-size: 18px;
			line-height: 21px;
			color: $primaryColor;
			font-weight: 600;
			padding-bottom: 8px;
			display: inline-block;
			padding-right: 15px;
			text-transform: uppercase;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				height: 3px;
				background-color: $fourthColor;
			}
		}

		&::before {
			content: "";
			position: absolute;
			bottom: 1px;
			left: 0;
			right: 0;
			height: 1px;
			background-color: $primaryColor;
		}

	}


	.title-style-4 {
		display: block;
		position: relative;
		margin-bottom: 10px;

		span {
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor;
			font-weight: 600;
			padding-bottom: 8px;
			display: block;
			text-transform: uppercase;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 30px;
				right: 0;
				height: 3px;
				background-color: $fourthColor;
			}
		}

		&::before {
			content: "";
			position: absolute;
			bottom: 1px;
			left: 0;
			right: 0;
			height: 1px;
			background-color: $primaryColor;
		}

	}




	.sidebar-product-1 {

		ul {
			li {
				border-bottom: 1px solid #ECECEC;
				padding: 15px 0;

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}

				a {
					display: inline-block;
					width: 100%;

					&:hover {
						em {
							padding-left: 15px;
							transition: all ease 0.3s;
						}
					}

					img {
						float: left;
						width: 52px;
					}

					em {
						color: $colorb9b;
						position: relative;
						font-style: normal;
						float: left;
						width: calc(100% - 52px);
						padding-left: 10px;
						max-height: 52px;
						min-height: 52px;
						overflow: hidden;
						transition: all ease 0.3s;

						strong {
							display: block;
							font-weight: 400;
							font-size: 13px;
							line-height: 16px;
							max-height: 51px;
							overflow: hidden;
							@include center(vertical);

						}

						span {
							display: block;
						}
					}
				}
			}
		}
	}

	.sidebar-category-1 {

		ul {
			display: inline-block;
			margin-top: 10px;

			li {

				border-bottom: 1px solid #ECECEC;
				display: inline-block;
				width: 100%;
				transition: all ease 0.3s;
				padding: 10px 0;

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}




				a {
					display: inline-block;
					width: 100%;
					font-size: 13px;
					line-height: 16px;
					color: $colorb9b;
					padding-left: 20px;
					position: relative;
					font-weight: 400;

					transition: all ease 0.3s;

					&:hover {
						padding-left: 25px;
						color: $primaryColor;
						transition: all ease 0.3s;

						i {
							color: $primaryColor;
							transition: all ease 0.3s;
						}
					}

					span {
						max-height: 37px;
						overflow: hidden;
						display: block;
					}

					i {
						display: inline-block;
						color: $colorb9b;
						font-size: 20px;
						position: absolute;
						left: 0;
						text-align: center;
						@include center(vertical);
						transition: all ease 0.3s;
					}
				}
			}
		}
	}

	.sidebar-category-2 {

		padding: 0;

		ul {
			display: inline-block;
			width: 100%;

			li {

				border-bottom: 1px solid #ECECEC;
				display: inline-block;
				width: 100%;
				transition: all ease 0.3s;

				&:last-child {
					border-bottom: none;
					padding-bottom: 0;
				}

				&:hover {
					background-color: hoverBg;
					transition: all ease 0.3s;

					a {
						color: $primaryColor;
						transition: all ease 0.3s;

						i {
							color: $primaryColor;
							transition: all ease 0.3s;
						}
					}
				}

				a {
					display: inline-block;
					width: 100%;
					font-size: 13px;
					line-height: 16px;
					color: $colorb9b;
					padding-left: 20px;
					position: relative;
					font-weight: 400;
					padding: 10px 20px 10px 40px;
					transition: all ease 0.3s;

					span {
						max-height: 37px;
						overflow: hidden;
						display: block;
					}

					i {
						display: inline-block;
						color: $colorb9b;
						font-size: 20px;
						position: absolute;
						left: 0;
						width: 40px;
						text-align: center;
						@include center(vertical);
						transition: all ease 0.3s;
					}
				}
			}
		}
	}

	.sidebar-category-3 {

		padding: 0;
		box-shadow: inherit;

		&:hover {
			box-shadow: inherit;
		}

		ul {
			display: inline-block;
			width: 100%;

			li {


				display: inline-block;
				width: 100%;
				transition: all ease 0.3s;

				margin-bottom: -5px;

				&:last-child {
					a {
						border-bottom: none;
					}

					padding-bottom: 0;
				}

				&:hover {
					transition: all ease 0.3s;

					a {
						background-color: $fourthColor;
						transition: all ease 0.3s;
						color: $white;

						&:before {
							width: 4px;
							transition: all ease 0.2s;
						}

						i {
							color: $white;
							transition: all ease 0.3s;
						}
					}
				}

				a {
					display: inline-block;
					width: 100%;
					font-size: 14px;
					line-height: 16px;
					color: #4A4A4A;
					height: 50px;
					padding-left: 40px;
					padding-right: 5px;
					font-weight: 500;
					transition: all ease 0.3s;
					position: relative;
					border-bottom: 1px solid #ECECEC;

					&::before {
						content: "";
						width: 0;
						height: 100%;
						position: absolute;
						left: 0;
						top: 0;
						transition: all ease 0.2s;
						background-color: $primaryColor;
					}

					span {
						max-height: 37px;
						overflow: hidden;
						display: block;
						padding-right: 10px;
						@include center(vertical);
					}

					i {
						display: inline-block;
						color: #9B9B9B;
						font-size: 20px;
						font-weight: 500;
						position: absolute;
						left: 0;
						width: 40px;
						text-align: center;
						@include center(vertical);
						transition: all ease 0.3s;
					}
				}
			}
		}
	}





	.sidebar-category-4 {

		padding: 0;
		box-shadow: inherit;

		&:hover {
			box-shadow: inherit;
		}

		.nav-link[data-toggle].collapsed:after {
			content: "\f2fb";
			font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
			font-size: 20px;
			font-weight: 500;
			color: $colore7e;
			float: right;
		}


		.nav-link[data-toggle]:not(.collapsed):after {
			content: "\f2f9";
			font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
			font-size: 20px;
			font-weight: 500;
			color: $fourthColor;
			float: right;
		}


		.nav-link[data-toggle].collapsed {
			&::before {
				display: none !important;
			}
		}

		.nav-link[data-toggle]:not(.collapsed) {
			&::before {
				display: none !important;
			}
		}


		.nav-link[data-toggle]:not(.collapsed) {
			color: $primaryColor;

			b {
				color: $fourthColor;
				transition: all ease 0.3s;
			}
		}

		.nav {
			li {
				a {
					padding: 15px 0;
					color: $colore7e;
					font-size: 14px;
					line-height: 16px;
					font-weight: 500;
					border-bottom: 1px solid $colorcec;
					transition: all ease 0.3s;
					padding-right: 10px;

					&:hover {
						color: $primaryColor;
						transition: all ease 0.3s;

						b {
							color: $fourthColor;
							transition: all ease 0.3s;
						}

						&:after {
							color: $primaryColor;
						}
					}

					span {
						display: inline-block !important;

						b {
							color: #D8DCE9;
							font-size: 10px;
							line-height: 12px;
							margin-left: 7px;
							float: right;
							transition: all ease 0.3s;
						}
					}
				}

				ul {
					padding-left: 15px;

					li {
						a {

							position: relative;

							&::before {
								content: "\f301";
								font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
								font-size: 20px;
								font-weight: 500;
								color: $fourthColor;
								position: absolute;
								right: 10px;
								transition: all ease 0.3s;
								opacity: 0;
							}



							&:hover {
								&:before {
									right: 0;
									transition: all ease 0.3s;
									opacity: 1;
								}

								span {
									&::after {
										color: $primaryColor;
									}
								}
							}

							padding: 5px 0;
							color: $colore7e;
							font-size: 12px;
							line-height: 24px;
							border-bottom: none;

							span {
								&::before {
									content: "-";
									margin-right: 5px;
								}
							}
						}
					}
				}
			}

		}





	}





}




#about-page-content {



	.content {
		img {
			border-radius: 3px;
			box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);
			display: inline;
			background-color: #D8DCE9;
			margin: 30px;

			@include screen(mobile) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		p {
			font-size: 14px;
			line-height: 20px;
			color: #4A4A4A;
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}

		}

		strong {
			color: #4A4A4A;
			font-size: 14px;
		}
	}
}



#faq-page-content {
	.faq-content {
		margin-top: 100px;

		.accordionCustom {
			.contentWrap {
				font-size: 14px;
				line-height: 22px;
				color: #7E7E7E;
				position: relative;


				.card-body {
					padding: 0 40px 20px 40px;
					color: $colora4a;
					font-size: 14px;
					line-height: 24px;

					ul {
						display: inline-block;
						width: 100%;
						margin-top: 20px;
						padding-left: 20px;

						li {
							display: block;
							width: 100%;

							&:before {
								content: "";
								width: 5px;
								height: 5px;
								display: inline-block;
								background-color: $primaryColor;
								border-radius: 50%;
								margin-right: 10px;
							}
						}
					}
				}
			}
		}
	}

}

.faq-detail-wrap {
	.picture {
		border-radius: 3px;

		img {
			box-shadow: 0px 8px 44px rgba(0, 0, 0, 0.256142);
			border-radius: 3px;

			@include screen(mobile) {
				margin-bottom: 20px;
			}
		}
	}

	.text-area {
		margin-top: 35px;

		p {

			margin-bottom: 10px;
			font-size: 14px;
			line-height: 22px;
			color: $textColor;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}



.customAccordion1 {
	margin-bottom: 100px;

	.card {
		margin-bottom: 20px;
		border-radius: 3px;
		border: 1px solid #d8dce9 !important;


		&:last-child {
			margin-bottom: 0;
		}

		.card-header {
			padding: 0;
			border: none;
			background-color: #fff;

			&:last-child {
				margin-bottom: 0;
			}

			button {
				//          aria-expanded="true"

				&.btn-link {
					border: none !important;
					border-radius: 4px;
					padding-top: 10px;
					padding-bottom: 10px;
					width: 100%;
					text-align: left;
					border: none;
					padding-left: 40px;
					padding-right: 70px;
					min-height: 80px;
					font-size: 18px;
					line-height: 22px;
					color: #4A4A4A;
					font-weight: 500;
					position: relative;

					@include screen("mobile") {
						padding-left: 20px;
					}

					&:after {
						content: "\f2fb";
						width: 40px;
						height: 40px;
						font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
						border-radius: 50%;
						position: absolute;
						top: 20px;
						right: 20px;
						font-size: 24px;
						line-height: 40px;
						text-align: center;
						color: #7E7E7E;
						background-color: #ECECEC;
						transition: all ease 0.3s;
					}

					&:hover,
					&:focus,
					&:active {
						text-decoration: none;
					}
				}

				&[aria-expanded="true"] {
					color: $primaryColor;
					z-index: 1;

					&:after {
						transform: rotate(90deg);
						background-color: $primaryColor;
						color: #fff;
					}

				}
			}
		}

		.collapse {
			border-top: none;
		}


	}
}





.customAccordion2 {
	margin-bottom: 100px;

	.card {
		margin-bottom: 20px;
		border-radius: 3px;
		border: 1px solid #d8dce9 !important;


		&:last-child {
			margin-bottom: 0;
		}

		.card-header {
			padding: 0;
			border: none;
			background-color: #fff;

			&:last-child {
				margin-bottom: 0;
			}

			button {
				//          aria-expanded="true"

				&.btn-link {
					border: none !important;
					border-radius: 4px;
					padding-top: 10px;
					padding-bottom: 10px;
					width: 100%;
					text-align: left;
					border: none;
					padding-left: 40px;
					padding-right: 70px;
					min-height: 80px;
					font-size: 18px;
					line-height: 22px;
					color: #4A4A4A;
					font-weight: 500;
					position: relative;

					@include screen("mobile") {
						padding-left: 20px;
					}

					&:after {
						content: "\f2fb";
						width: 40px;
						height: 40px;
						font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
						border-radius: 50%;
						position: absolute;
						top: 20px;
						right: 20px;
						font-size: 24px;
						line-height: 40px;
						text-align: center;
						color: #7E7E7E;
						background-color: #ECECEC;
						transition: all ease 0.3s;
					}

					&:hover,
					&:focus,
					&:active {
						text-decoration: none;
					}
				}

				&[aria-expanded="true"] {
					color: $fourthColor;
					z-index: 1;

					&:after {
						transform: rotate(90deg);
						background-color: $fourthColor;
						color: #fff;
					}

				}
			}
		}

		.collapse {
			border-top: none;
		}

		.contentWrap {
			position: relative;

			&:before {
				content: "";
				width: 6px;
				height: calc(100% - 11px);
				display: inline-block;
				position: absolute;
				top: 0;
				left: 18px;
				border-radius: 5px;
				background-color: $fourthColor;
			}


		}
	}
}



#hr-content-area {}

.hr-content-list {

	.job {
		padding: 13px;
		border: 1px solid #D8DCE9;
		border-radius: 10px;
		transition: all ease 0.3s;
		margin-bottom: 30px;

		@include screen(mobile) {
			text-align: center;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			transition: all ease 0.3s;
			border-color: $fourthColor;
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);

			i {
				color: $fourthColor !important;
				transition: all ease 0.3s;
			}

			span {
				color: $fourthColor;
				transition: all ease 0.3s;
			}

		}

		.item-container {
			min-height: 100px;
			display: inline-block;
			width: 100%;
		}

		.job-icon {

			text-align: center;

			i {
				color: $primaryColor;
				font-size: 72px;
				line-height: 100px;
				transition: all ease 0.3s;

			}
		}

		.link-field {
			text-align: right;
			line-height: 100px;

			@include screen(mobile) {
				text-align: center;

			}

			a {
				color: #768BBE;
				font-size: 13px;
				line-height: 54px;
				border: 2px solid #D8DCE9;
				border-radius: 4px;
				display: inline-block;
				padding: 0 50px;
				margin-right: 15px;
				transition: all ease 0.3s;
				font-weight: 500;
				max-width: 100%;

				@include screen(mobile) {
					margin-right: 0;

				}

				@include screen(tablet) {
					text-align: center;
					width: 100%;
					text-align: center;
					padding: 0;

				}

				&:hover {
					color: $white !important;
					background-color: $primaryColor;
					border-color: $primaryColor !important;
					transition: all ease 0.3s;
				}
			}
		}

		span {
			display: block;
			font-size: 18px;
			line-height: 22px;
			color: $primaryColor;
			margin-bottom: 5px;
			font-weight: 500;
			transition: all ease 0.3s;
			margin-top: 5px;
		}

		p {
			color: $colore7e;
			font-size: 14px;
			line-height: 20px;
			display: block;
		}

	}
}


.hr-detail-content-list {
	.job {

		border: 1px solid #D8DCE9;
		border-radius: 10px;
		transition: all ease 0.3s;
		padding: 30px;




		.item-container {
			min-height: 100px;
			display: inline-block;
			width: 100%;

			span {
				&.title {
					font-size: 18px;
					line-height: 22px;
					color: $primaryColor;
					display: block;
					margin-bottom: 10px;
					font-weight: 500;
				}
			}
		}

		.job-icon {

			text-align: center;

			img {
				display: block;
				margin: 0 auto;
				margin-top: 20px;
			}
		}

		ul {
			display: inline-block;
			width: 100%;
			margin-top: 15px;

			li {
				@include screen(tablet) {
					width: 100%;
				}

				@include screen(mobile) {
					width: 100%;
				}

				float: left;
				width: 50%;
				margin-bottom: 10px;
				padding-left: 20px;
				position: relative;
				min-height: 50px;

				span {
					font-size: 12px;
					color: $colore7e;
					line-height: 16px;
					font-weight: 400;
					display: block;
					@include center(vertical);
				}

				&:before {
					content: "";
					width: 10px;
					height: 10px;
					border-radius: 5px;
					top: 0;
					left: 0;
					background-color: $primaryLightColor;
					@include center(vertical);
				}
			}
		}

		p {
			color: $colore7e;
			font-size: 14px;
			line-height: 20px;
			display: block;
		}

	}
}




#hr-sidebar {
	.upload-area {
		display: inline-block;
		width: 100%;
		height: 250px;
		border: 1px dashed #D8DCE9;
		position: relative;
		margin-bottom: 100px;

		form {
			display: inline-block;
			width: 100%;
			height: 250px;
		}

		input {
			position: absolute;
			margin-bottom: 0 !important;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			opacity: 0;

			&:hover {
				cursor: pointer;
			}
		}

		.text-icon {
			text-align: center;
			width: 100%;

			@include screen(tablet) {
				max-width: 80%;
			}

			@include center(both);

			i {
				font-size: 72px;
				color: #D8DCE9;
				display: block;

			}

			strong {
				font-size: 14px;
				line-height: 24px;
				color: #4A4A4A;
				display: block;
			}

			span {
				font-size: 11px;
				line-height: 24px;
				color: #9B9B9B;
				display: block;
			}
		}

		.text {
			b {
				font-size: 11px;
				line-height: 16px;
				color: #4A4A4A;
				display: block;
				text-align: center;
				font-weight: 500;
				margin-top: 10px;

				i {
					font-style: normal;
					display: block;
					font-size: 11px;

				}
			}
		}
	}

	.frame {

		width: 100%;
		border-radius: 3px;
		display: inline-block;
		width: 100%;
	 	color: #fff;
		margin-bottom: 50px;

		.text {
			b {
				font-size: 11px;
				line-height: 16px;
				color: #4A4A4A;
				display: block;
				text-align: center;
				font-weight: 500;
				margin-top: 10px;

				i {
					font-style: normal;
					display: block;
					font-size: 11px;

				}
			}
		}
	}

	.center {
		position: relative;
		width: 100%;
		height: 260px;
		background: #fff;
		border-radius: 3px;
		border: 1px dashed $colorce9;


		strong {
			&.select-image{
				font-size: 14px;
				line-height: 24px;
				color: #4A4A4A;
				display: block;
			}
		}

		span {
			&.how-make{
				font-size: 11px;
				line-height: 24px;
				color: #9B9B9B;
				display: block;
			}
		}
	}


	.dropzone {
		position: absolute;
		z-index: 1;
		box-sizing: border-box;
		display: table;
		table-layout: fixed;
		width: 100%;
		height: 255px;
		border-radius: 3px;
		text-align: center;
		overflow: hidden;

		&.is-dragover {
			border-color: #666;
			background: #eee;
		}

		.content {
			display: table-cell;
			vertical-align: middle;

			i{
				font-size: 72px;
				color: $colorce9;
			}
		}

		.upload {
			margin: 6px 0 0 2px;
		}

		.filename {
			display: block;
			color: #4A4A4A;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
		}

		.input {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			width: 100%;
			&:hover{
				cursor: pointer;
			}
		}

	}










}



.hr-form-area {
	margin-top: 35px;

	.form-control {
		margin-bottom: 30px;
	}

	button {
		float: right;
		padding: 18px 32px !important;
	}
}




#footer-banner-text-area {
	margin-top: 50px;
	padding-bottom: 50px;
	text-align: center;

	p {

		font-size: 36px;
		color: $colore7e;
		display: block;
		line-height: 44px;
		margin-bottom: 30px;
	}
}


.form-control {
	width: 100%;
	border-radius: 4px;
	border: 1px solid $thirdColor;
	transition: all ease 0.3s;
	height: 50px;

	&[type="date"]::-webkit-inner-spin-button,
	&[type="date"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}

	&.textarea-field {
		height: 170px;
		resize: none;
		padding-top: 10px;
	}

	&:hover,
	&:focus,
	&:active {
		border-color: $primaryColor;
		transition: all ease 0.3s;
		outline: none;
		box-shadow: inherit;
	}


}

label {
	&.primary-label {
		font-size: 14px;
		line-height: 24px;
		color: $primaryColor;
		margin-bottom: 4px;
		display: block;
		padding-left: 15px;
		font-weight: 500;
	}
}











.custom-checkbox-1 {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 12px;
	color: #888082;
	line-height: 16px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-top: 10px;
	margin-bottom: 20px;

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	b {
		color: $primaryColor;
		font-weight: 500;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #fff;
		border-radius: 5px;
		border: 2px solid #dddddd;
	}
}

/* Hide the browser's default checkbox */


/* Create a custom checkbox */


/* On mouse-over, add a grey background color */
.custom-checkbox-1:hover input ~ .checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-1 input:checked ~ .checkmark {
	background-color: $primaryColor;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.custom-checkbox-1 input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-1 .checkmark:after {
	content: "\f26b";
	font: normal normal normal 15px/1 'Material-Design-Iconic-Font';
	left: 3px;
	top: 0;
	font-weight: bold;
	width: 8px;
	height: 10px;
	color: #fff;

}






#product-page-content {
	.product-item-container {
		margin: 0;
		margin-bottom: 30px;
	}

	.slider-product-detail-area {
		position: relative;

		.prop {
			position: absolute;
			top: 10px;
			left: 10px;
			z-index: 55;

			i {

				line-height: 20px;
				padding: 0 5px;
				font-size: 12px;
				color: $white;
				font-style: normal;
				font-weight: bold;
				float: left;
				margin-right: 10px;

				&:last-child {
					margin-right: 0;
				}

				&.new {

					background-color: $primaryLightColor;
				}

				&.discount {
					background-color: $color321;
				}
			}
		}
	}
}

#slider-product-detail {
	margin-bottom: 60px;

	.product-detail-item-container {
		img {
			border-radius: 4px;
			width: 100%;
		}
	}


}


.product-detail-product-banner {
	.product-detail-title-1 {
		ul {
			display: inline-block;
			width: 100%;

			li {
				display: inline-block;
				width: 100%;
				font-size: 17px;
				line-height: 20px;
				color: $colore7e;
				font-weight: 500;
				padding: 10px 0;
				border-bottom: 1px solid $colorcec;

				&:last-child {
					border: none;
				}

				span {
					margin-left: 5px;
					color: $primaryColor;
					font-size: 15px;
				}

				strong {
					color: $primaryColor;
					font-size: 36px;
					font-weight: bold;
					line-height: 80px;
					float: left;
					padding-right: 50px;

					i {
						font-style: normal;
						font-size: 27px;
					}
				}

				b {
					font-size: 18px;
					line-height: 80px;
					text-decoration: line-through;
					display: inline-block;
					font-weight: 400;
					float: left;

					i {
						font-size: 12px;
						margin-top: 1px;
						color: $color6c6;
						line-height: 77px;
						font-style: normal;
						float: right;
						font-weight: 400;
						text-decoration: line-through;
					}
				}
			}
		}
	}

	.product-detail-title-2 {
		.product-details-top {
			.title {
				font-size: 24px;
				line-height: 34px;
				color: $primaryColor;
				display: block;
				padding-bottom: 15px;
				font-weight: 500;
				display: inline-block;
				width: 100%;
			}

			.product-cat {
				width: 50%;
				float: left;
				@include screen(mobile){
					width: 100%;
				}
				.cat-text {

					font-size: 14px;
					line-height: 40px;
					font-weight: 400;
					color: $colore7e;

					span {
						font-weight: 500;

					}
				}

				.tag-text {
					a {
						background-color: $colorcec;
						border-radius: 20px;
						font-size: 14px;
						font-weight: 500;
						color: $colore7e;
						display: inline-block;
						padding: 5px 20px;
						margin-bottom: 5px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}

			}

			.product-rating {
				width: 50%;
				float: right;
				padding: 3px 0;
				@include screen(mobile){
					width: 100%;
					margin-top: 10px;
				}
				fieldset {
					margin: 0;
					padding: 0;
					float: right;
				}

				label {
					margin-bottom: 0;
				}

				.rating {
					border: none;
					float: right;
					width: 100%;
					@include screen(mobile){
						width: auto;
						display: inline-block;
						float: left;
					}
				}

				.rating > input {
					display: none;
				}

				.rating > label:before {
					margin: 5px;

					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					display: inline-block;
					content: "\f27d";
					font-size: 24px;
				}

				.rating > .half:before {
					content: "\f27b";
					position: absolute;
				}

				.rating > label {
					color: $colore7e;
					float: right;
				}

				/***** CSS Magic to Highlight Stars on Hover *****/

				.rating > input:checked ~ label,
				/* show gold star when clicked */
				.rating:not(:checked) > label:hover,
				/* hover current star */
				.rating:not(:checked) > label:hover ~ label {
					color: $primaryLightColor;
				}

				/* hover previous stars in list */

				.rating > input:checked + label:hover,
				/* hover current star when changing rating */
				.rating > input:checked ~ label:hover,
				.rating > label:hover ~ input:checked ~ label,
				/* lighten current selection */
				.rating > input:checked ~ label:hover ~ label {
					color: $primaryLightColor;
				}

				.comment-text {
					display: inline-block;
					width: 100%;
					float: right;
					text-align: right;
					padding: 6px 0;
					
					@include screen(mobile){
						text-align: left;
					}
					a {
						font-size: 14px;
						line-height: 20px;
						color: $colorb9b;
						margin-left: 5px;

						&:first-child {
							margin-left: 0;

							&:before {
								display: none;
							}
						}

						&:before {
							content: "|";
							margin-right: 5px;

						}
					}
				}
			}

			.product-price {
				display: inline-block;
				float: left;
				width: 100%;
				padding: 10px 0;
				margin-bottom: 30px;
					@include screen(mobile){
						margin-bottom: 0;
					}
				strong {
					color: $primaryColor;
					font-size: 36px;
					font-weight: bold;
					line-height: 60px;
					float: left;
					padding-right: 50px;
@include screen(mobile){
						line-height: 40px;
					}
					i {
						font-style: normal;
						font-size: 27px;
					}
				}

				b {
					font-size: 18px;
					line-height: 60px;
					text-decoration: line-through;
					display: inline-block;
					font-weight: 400;
					float: left;
					color: $color6c6;
@include screen(mobile){
					line-height: 40px;
					}
					i {
						font-size: 12px;
						margin-top: 1px;
						color: $color6c6;
						line-height: 57px;
						font-style: normal;
						float: right;
						font-weight: 400;
						text-decoration: line-through;
						@include screen(mobile){
						line-height: 37px;
					}
					}
				}
			}

			.product-bucket {
				@include screen(mobile){
					 margin-top: 5px;
					}
				button {
					border-radius: 4px;
					
					span {
						font-size: 13px;
						line-height: 55px;
						font-weight: 500;
						display: inline-block;

						@include screen(mobile) {}

						i {

							font-size: 24px;
							font-weight: 500;
							float: left;
							line-height: 55px;
							margin-right: 8px;
						}
					}

					&.add-bucket {
						width: 100%;
						float: left;
						background-color: $color9ff;
						transition: all ease 0.3s;

						&:hover {
							background-color: $fourthColor;
							transition: all ease 0.3s;

							span {
								color: #fff;
							}
						}

						span {


							color: $color7f7;

						}
					}

					&.document-bucket {
						width: 48%;
						float: left;
						margin-top: 12px;
						background-color: $colorfb5;
@include screen(mobile){
						width: 100%;
					}
						&:hover {
							background-color: $primaryLightColor;
							transition: all ease 0.3s;

							span {
								color: #fff;
							}
						}

						span {

							color: $primaryLightColor;
						}

					}

					&.give-price {
						width: 48%;
						float: right;
						margin-top: 12px;
						background-color: $colorfcf;
@include screen(mobile){
						width: 100%;
					}
						&:hover {
							background-color: $color321;
							transition: all ease 0.3s;

							span {
								color: #fff;
							}
						}

						span {
							color: $color321;
						}

					}
				}
			}


		}



	}

}



.product-detail-page-product-properties-area {
	margin-top: 75px;
}



.product-detail-explain-area-1 {
	margin-top: 30px;
	padding: 30px;
	border: 1px solid $thirdColor;

	.title {
		color: $primaryColor;
		font-size: 18px;
		line-height: 40px;
		display: block;
		font-weight: 500;
		margin-bottom: 10px;
	}

	p {
		font-size: 16px;
		line-height: 24px;
		color: $colora4a;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

}






.productDetailTab {
	border-radius: 5px;

	#productTab {

		background-color: #fff;
		border-bottom: none;
		padding-left: 20px;
@include screen(mobile){
					padding-left: 0;
				}
		li {
			text-align: center;
			margin-right: 10px;
			margin-bottom: 0;

			&:last-child {
				margin-right: 0;
			}

			&:first-child {
				a {
					border-bottom-left-radius: 0;
				}
			}

			a {
				@include screen(mobile) {
					padding: 5px 8px; 
				}

				font-size: 14px;
				color: $primaryColor;
				font-weight: 500;
				padding: 12px 50px;
				display: block;
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
				margin-top: 10px;
				transition: all ease 0.3s;
				margin-bottom: -2px;
				border-bottom: none;
				border: 2px solid $colorcec;
				border-bottom: none;

				&:focus,
				&:active {
					border-color: $primaryColor $primaryColor #fff;
				}

				&:hover {
					border-color: $colorcec $colorcec $colorcec;
				}

				&.active {

					color: #fff;
					border-color: $primaryColor $primaryColor $primaryColor;

					transition: all ease 0.3s;
					background-color: $primaryColor;
					border-width: 2px;

					 
				}
			}
		}
	}

	#tabContent {

		border: 2px solid $colorcec;
		border-radius: 4px;

		.tab-pane {
			padding: 30px 30px 50px 30px;

			> p {
				font-size: 13px;
				color: $colora4a;
				line-height: 23px;
				display: block;
				margin-bottom: 30px;
			}
		}





	}
}





#gallery-page-content {}


.gallery-post-list-content {
	.gallery-item {
		margin-bottom: 30px;

		a {
			display: inline-block;
			width: 100%;
			position: relative;
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);
			border-radius: 4px;
			transition: all ease 0.3s;

			&:hover {
				em {
					min-height: 100%;
					transition: all ease 0.5s;
					border-radius: 4px;
				}

				i {
					&.icon-top {
						opacity: 0;
						transition: all ease 0.5s;
					}
				}

				b {
					transition: all ease 0.6s;
					bottom: 30px;
					opacity: 1;
				}
			}

			i {
				&.icon-top {
					display: inline-block;
					width: 60px;
					height: 60px;
					line-height: 60px;
					position: absolute;
					top: 0;
					right: 0;
					border-radius: 0px 4px;
					background-color: $color7f7;
					transition: all ease 0.3s;
					font-size: 36px;
					color: #fff;
					text-align: center;
				}

				&.zmdi {}
			}

			img {
				border-radius: 4px;
			}

			em {
				font-style: normal;
				padding: 25px 30px;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				min-height: 20px;
				border-radius: 0px 0px 4px 4px;
				background-color: rgba(0, 0, 0, 0.6);
				transition: all ease 0.5s;

				span {
					font-size: 20px;
					line-height: 23px;
					color: #fff;
					font-weight: 500;
				}

				b {
					display: inline-block;
					font-size: 14px;
					color: #fff;
					line-height: 20px;
					font-weight: 500;
					position: absolute;
					bottom: 0;
					left: 30px;
					opacity: 0;
					transition: all ease 0.3s;

					&:hover {
						i {
							margin-left: 20px;
							transition: all ease 0.3s;
						}
					}

					i {
						font-size: 24px;
						line-height: 20px;
						color: #fff;
						float: right;
						margin-left: 15px;
						font-weight: 500;
						transition: all ease 0.3s;


					}
				}
			}
		}
	}
}


.gallery-detail-post-list-content {
	.gallery-item {
		margin-bottom: 30px;

		a {
			display: inline-block;
			width: 100%;
			position: relative;

			img {
				border-radius: 4px;
			}

			&:hover {
				em {
					width: 100%;
					height: 100%;
					transition: all ease 0.3s;
				}
			}

			em {
				position: absolute;
				@include center(both);
				width: 0;
				overflow: hidden;
				height: 0;
				text-align: center;
				transition: all ease 0.3s;
				border-radius: 4px;
				background-color: rgba(0, 0, 0, 0.6);

				i {
					font-size: 64px;
					color: #fff;
					@include center(both);


				}
			}
		}
	}
}




#footer-contact-form-area {
	padding: 70px 0;
	background-color: $primaryColor;

	.formWrap {
		padding: 0 20px;
		display: inline-block;
		width: 100%;

		label {
			font-size: 13px;
			line-height: 15px;
			color: #D8DCE9;
			font-weight: 500;
			padding-left: 15px;
		}

		strong {
			display: block;
			text-align: center;
			font-size: 36px;
			font-weight: 300;
			line-height: 30px;
			color: #D8DCE9;
			margin-bottom: 36px;

		}

		input {
			background-color: $white;
			border-radius: 4px;
			height: 55px;
		}

		.inpt {
			float: left;


		}

		.left {
			width: calc(100% - 175px);
			float: left;

			@include screen(mobile) {
				width: 100%;

			}

			.inpt {
				width: calc(100% / 2);
				padding-right: 15px;

				@include screen(mobile) {
					width: 100%;
					margin-top: 15px;
					padding-right: 0;
				}

			}


		}

		.right {
			width: 175px;
			float: right;

			@include screen(mobile) {
				width: 100%;
			}

			.inpt {

				width: 100%;



				button {
					width: 100%;
					margin-top: 24px;
					height: 55px;
					font-size: 13px;
					font-weight: 500;

				}
			}


		}
	}
}


#team-detail-page-content {}


.team-detail-content {
	.team-single-item-container {
		margin: 0 0 30px 0px;

		&:hover {
			margin-top: 0 !important;
		}
	}
}

.reference-detail-content {
	.reference-logo-item-container {
		margin: 0 0 30px 0;
	}
}


#branch-offices-detail-page-content {}

#contact-us-page-container {
	iframe {
		height: 500px;
		width: 100%;
		margin-top: 40px;
	}

	.big-title-area {
		margin-top: 120px;
		position: relative;

		@include screen(mobile) {
			margin-top: 30px;
		}

		&:after {
			content: "\f194";
			font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
			font-size: 288px;
			position: absolute;
			right: -50px;
			top: -50px;
			transform: rotate(-30deg);
			color: $primaryColor;
			opacity: 0.1;
			line-height: 215px;

		}
	}

	.hr-form-area {
		margin-bottom: 100px;
	}

	.btn-send {

		float: right;
	}


}

.contact-us-page-adress-wrapper {
	.social {
		strong {
			font-size: 24px;
			line-height: 28px;
			display: block;
			color: $colora4a;
			margin-bottom: 10px;
		}

		ul {
			display: inline-block;
			width: 100%;

			li {
				float: left;
				margin-right: 15px;

				&:last-child {
					margin-right: 0;
				}

				a {
					font-size: 36px;
					color: $color6d6;
					transition: all ease 0.3s;

					&:hover {
						color: $primaryColor;
						transition: all ease 0.3s;
					}
				}
			}
		}

		a {
			&.send-message {
				display: block;
				margin-top: 60px;
				border-radius: 4px;
				background-color: $primaryColor;
				text-align: center;

				@include screen(mobile) {
					margin-top: 10px;
					margin-bottom: 15px;
				}

				span {
					font-size: 13px;
					line-height: 54px;
					color: #fff;
					display: inline-block;
					font-weight: 500;

					&:before {
						content: "\f2fe";
						font-size: 20px;
						color: $fourthColor;
						font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
						line-height: 54px;
						float: left;
						margin-right: 10px;
					}
				}
			}
		}
	}

	.contact-list {

		span {
			font-size: 20px;
			line-height: 26px;
			color: $colorb9b;
			display: block;
			margin-bottom: 10px;
		}

		a {
			padding-left: 25px;
			position: relative;
			display: inline-block;
			font-size: 14px;
			line-height: 25px;
			font-weight: 400;
			color: $colora4a;
			margin-bottom: 30px;

			&:before {
				content: "";
				font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
				font-size: 18px;
				color: $fourthColor;
				position: absolute;
				left: 0;
				@include center(vertical);
			}

			&.adress {
				&:before {
					content: "\f1ab";
				}
			}

			&.telephone {
				&:before {
					content: "\f2be";
				}
			}

			&.fax {
				&:before {
					content: "\f1b0";
				}
			}

			&.posta {
				&:before {
					content: "\f15a";
				}
			}
		}
	}



}


#projects-page-container {

	#slider-tab-cat {
		display: inline-block;
		width: 100%;
	}

	.post-project-item-container {
		margin: 0;
		margin-bottom: 30px;
	}

	.project-page-title {
		font-size: 14px;
		line-height: 24px;
		color: $colora4a;
		display: block;
		margin-bottom: 40px;
		font-weight: 500;
	}



}


#project-page-slider {

	.slider-item {
		img {
			border-radius: 4px;
		}
	}

	button {
		&.slick-arrow {
			width: 48px;
			height: 48px;
			border: 2px solid #fff;
			border-radius: 3px;
			transition: all ease .3s;
			z-index: 555;

			&:hover,
			&:focus,
			&:active {
				border-color: $primaryColor;
				background-color: $primaryColor;
				transition: all ease .3s;

				&::before {
					transition: all ease .3s;
					color: #fff;
				}

			}

			&::before {
				color: #fff;
				font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
				font-size: 36px;
				transition: all ease .3s;
			}
		}

		&.slick-prev {
			left: 12px;

			&::before {
				content: '\f2fa';

			}
		}

		&.slick-next {
			right: 12px;

			&::before {
				content: '\f2fb';
			}
		}
	}

}



.project-detail-page-title {
	text-align: center;
	display: block;
	padding: 40px 20px;

}

.project-detail-page-title-2 {
	text-align: left;
	display: block;

}

.project-detail-page-title-style {
	font-weight: 500;
	font-size: 36px;
	line-height: 42px;
}

.project-detail-content-style-3 {
	.project-text-area {
		margin-top: 10px !important;
	}
}


#project-detail-page-container {

position: relative;
	.project-detail-banner-style-3 {
		margin-bottom: 20px;

		img {
			border-radius: 4px;
		}
	}

	.project-text-area {
		font-size: 24px;
		font-weight: 400;
		color: $colore7e;
		line-height: 30px;
		margin-top: 40px;
	}

	.project-text-area-style-2 {

		margin-top: 30px;
		margin-bottom: 60px;

		p {
			font-size: 18px;
			font-weight: 400;
			color: $colore7e;
			line-height: 33px;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ul {

		&.project-prop {
			display: inline-block;
			width: 100%;
			margin-top: 40px;

			&.project-prop-style-3 {
				margin-bottom: 20px;
				margin-top: 0;

				li {
					span {
						float: none;
						width: 100%;
						margin-bottom: 5px;
						padding-right: 5px;
					}

					strong {
						float: none;
						width: 100%;
					}
				}
			}

			li {
				font-size: 14px;
				line-height: 16px;
				padding: 7px 0;
				border-bottom: 1px solid $colorcec;
				display: inline-block;
				width: 100%;

				span {
					color: $colorb9b;
					font-weight: 400;
					display: inline-block;
					width: 50%;
					float: left;
				}

				strong {
					font-weight: 500;
					color: $colora4a;
					display: inline-block;
					width: 50%;
					float: left;
				}
			}
		}
	}
}


#project-detail-out-sidebar{
	position: absolute;
	top: 0;
	left: 0;
	width: 270px;
}


.project-content-gallery {
	a {
		@include screen(mobile) {
			width: 50%;

		}

		width: 25%;
		float: left;
		display: inline-block;
		padding-right: 10px;

		img {
			margin-bottom: 30px;

			@include screen(mobile) {
				margin-bottom: 10px;
			}

		}
	}
}



.project-detail-sidebar {
	margin-bottom: 30px;

	i {
		&.zmdi-navigation {
			transform: rotate(30deg);
			margin-top: -1px;
		}
	}

	ul {
		li {
			text-align: left;
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				display: block;
				border-radius: 4px;
				border: 1px solid $colorce9;
				transition: all ease 0.3s;

				&:hover {
					background-color: $primaryColor;
					transition: all ease 0.3s;
					border-color: $primaryColor;

					span {
						color: #fff;
						transition: all ease 0.3s;
					}
				}

				span {
					font-size: 13px;
					line-height: 54px;
					color: $primaryColor;
					display: inline-block;
					transition: all ease 0.3s;
					font-weight: 500;
					width: 100%;

					i {
						font-size: 24px;
						float: left;
						margin-right: 20px;
						margin-left: 20px;
						line-height: 54px;


					}
				}
			}
		}
	}
}




.branch-offices-detail-content {

	&.branch-offices-detail-content-style-1 {
		.see-all-branches {
			padding: 10px 0;
			margin-bottom: 70px;

			@include screen(mobile) {
				margin-bottom: 10px;
			}

			@include screen(tablet) {
				margin-bottom: 10px;
			}
		}
	}

	iframe {
		border: 2px solid $colorce9;
		border-radius: 4px;
		width: 100%;
	}

	.see-all-branches {
		display: inline-block;
		width: 100%;
		padding: 30px 0;

		.dropdown-menu {
			padding: 0;

			a {
				color: $colora4a;
				font-size: 14px;
				display: block;
				line-height: 25px;
			}
		}

		.dropdown {
			float: right;

			.dropdown-toggle {
				border-radius: 21px;
				background-color: $colorcec;
				border: none;
				font-size: 17px;
				line-height: 22px;
				padding: 10px 25px;
				color: $colora4a;

				&:after {
					display: none;
				}

				&:before {
					content: "\f2f9";
					color: $colora4a;
					font-size: 23px;
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';


				}
			}
		}
	}



}

.branch-list-item-container {
	margin-bottom: 100px;

	@include screen(mobile) {
		margin-bottom: 20px;
		padding-bottom: 10px;
		border-bottom: 1px solid #ccc;
	}

	&:hover {
		.branch-title {
			color: $fourthColor;
			transition: all ease 0.3s;
		}
	}

	.branch-title {
		font-size: 48px;
		line-height: 50px;
		display: block;
		color: $colora4a;
		font-weight: 600;
		margin-bottom: 30px;
		transition: all ease 0.3s;
	}

	a {
		&.contactLink {
			font-size: 18px;
			line-height: 28px;
			display: inline-block;
			color: $colora4a;
		}

		&.way-direction-link {
			margin-top: 50px;

			&:hover {
				transition: all ease 0.3s;

				span {
					color: #fff;
				}

				background-color: $fourthColor;
			}

			display: inline-block;
			width: 100%;
			text-align: center;
			line-height: 55px;
			border: 1px solid $colorce9;
			transition: all ease 0.3s;
			border-radius: 4px;

			span {
				font-size: 13px;
				font-weight: 500;
				color: $primaryColor;
				line-height: 25px;
				transition: all ease 0.3s;
				display: inline-block;

				i {
					font-size: 24px;
					float: left;
					margin-right: 10px;
					transform: rotate(30deg);
				}
			}

		}
	}

	ul {
		li {
			display: block;
			margin-bottom: 17px;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				font-size: 18px;
				line-height: 28px;
				display: inline-block;
				color: $colora4a;
			}

			span {
				font-size: 18px;
				line-height: 28px;
				display: inline-block;
				color: $colora4a;
			}
		}
	}

	iframe {
		height: 300px;

		@include screen(mobile) {
			margin-top: 30px;
		}
	}

}





.branch-offices-style-2 {
	iframe {
		height: 550px;
	}
}



.branch-list-style-2-content {
	.branch-item-container {
		padding: 25px 30px;
		margin-bottom: 50px;
		border-radius: 4px;
		transition: all ease 0.3s;

		@include screen(mobile) {
			margin-bottom: 0;
		}

		&:hover {
			box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);
			transition: all ease 0.3s;
		}

		strong {
			font-size: 24px;
			line-height: 28px;
			color: $primaryColor;
			display: block;
			padding-bottom: 5px;
		}

		.adress {
			margin-bottom: 30px;

			a {
				font-size: 18px;
				line-height: 28px;
				color: $colora4a;
				display: block;
			}
		}

		ul {
			li {
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					font-size: 18px;
					line-height: 24px;
					display: inline-block;
					font-weight: 400;

					i {
						font-size: 24px;
						color: $color6d6;
						line-height: 24px;
						width: 30px;
						float: left;
					}
				}
			}
		}
	}
}


















































/*


#slider-news-area {
	#slider-news { 
		margin-bottom: 50px; 
		button {
			&.slick-arrow {
				width: 48px;
				height: 48px;
				border: 2px solid #D8DCE9;
				background-color: #FFFFFF;
				border-radius: 3px;
				transition: all ease .3s;

				&:hover,
				&:focus,
				&:active {
					border-color: $primaryLightColor;
					transition: all ease .3s;

					&::before {
						transition: all ease .3s;
						color: $primaryLightColor;
					}

				}

				&::before {
					color: $primaryColor;
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					font-size: 36px;
					transition: all ease .3s;
				}
			}

			&.slick-prev {
				left: -90px;

				&::before {
					content: '\f2fa';

				}
			}

			&.slick-next {
				right: -90px;

				&::before {
					content: '\f2fb';
				}
			}
		}


		.slider-item {
			.item-container {
			

				margin: 25px 15px 25px 15px;  
				border-radius: 4px; 
				transition: all ease .3s;
				position: relative;
				box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.103969);
			 
				 	&:hover {

					transition: all ease .3s;
					box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.240206);
				 

				}


			}
		}
	}
}


*/
