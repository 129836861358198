

* {
    &,
    &:before,
    &:after {
      box-sizing: inherit;
    }
  }
  
  html {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
  }
  
  body {
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  a {
    text-decoration: none;
  }
  
  img {
    vertical-align: middle;
  }
  
  blockquote, dl, dd, h1, h2, h3, h4, h5, h6, figure, p, pre, fieldset, ul, ol, menu, form {
    margin: 0;
  }
  
  button, fieldset, iframe {
    border: 0;
  }
  
  fieldset, ul, ol, button, menu {
    padding: 0;
  }
  
  ol, ul {
    list-style: none;
  }
  
  textarea {
    resize: vertical;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  td {
    padding: 0;
  }