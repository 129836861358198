@font-face {
  font-family: 'GothamRounded';
  src: url('../fonts/GothamRounded/GothamRounded-Light.eot');
  src: url('../fonts/GothamRounded/GothamRounded-Light.woff2') format('woff2'),
  url('../fonts/GothamRounded/GothamRounded-Light.woff') format('woff'),
  url('../fonts/GothamRounded/GothamRounded-Light.ttf') format('truetype'),
  url('../fonts/GothamRounded/GothamRounded-Light.svg#GothamRounded-Light') format('svg'),
  url('../fonts/GothamRounded/GothamRounded-Light.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded';
  src: url('../fonts/GothamRounded/GothamRounded-Book.eot');
  src: url('../fonts/GothamRounded/GothamRounded-Book.woff2') format('woff2'),
  url('../fonts/GothamRounded/GothamRounded-Book.woff') format('woff'),
  url('../fonts/GothamRounded/GothamRounded-Book.ttf') format('truetype'),
  url('../fonts/GothamRounded/GothamRounded-Book.svg#GothamRounded-Book') format('svg'),
  url('../fonts/GothamRounded/GothamRounded-Book.eot?#iefix') format('embedded-opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded';
  src: url('../fonts/GothamRounded/GothamRounded-Medium.eot');
  src: url('../fonts/GothamRounded/GothamRounded-Medium.woff2') format('woff2'),
  url('../fonts/GothamRounded/GothamRounded-Medium.woff') format('woff'),
  url('../fonts/GothamRounded/GothamRounded-Medium.ttf') format('truetype'),
  url('../fonts/GothamRounded/GothamRounded-Medium.svg#GothamRounded-Medium') format('svg'),
  url('../fonts/GothamRounded/GothamRounded-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded';
  src: url('../fonts/GothamRounded/GothamRounded-Bold.eot');
  src: url('../fonts/GothamRounded/GothamRounded-Bold.woff2') format('woff2'),
  url('../fonts/GothamRounded/GothamRounded-Bold.woff') format('woff'),
  url('../fonts/GothamRounded/GothamRounded-Bold.ttf') format('truetype'),
  url('../fonts/GothamRounded/GothamRounded-Bold.svg#GothamRounded-Bold') format('svg'),
  url('../fonts/GothamRounded/GothamRounded-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'lg';
  src: url("../fonts/lg.eot?n1z373");
  src: url("../fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../fonts/lg.woff?n1z373") format("woff"), url("../fonts/lg.ttf?n1z373") format("truetype"), url("../fonts/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal;
}
